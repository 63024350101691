import React from "react";
import {
  Comment as SemanticComment,
  Loader,
  Header,
  Form,
  Button,
  Divider,
} from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import Comment from "./Comment.js";
import { createComment, getComments, deleteComment } from "../util/DbHelper.js";
import * as Sentry from "@sentry/react";

class CommentGroup extends React.Component {
  constructor(props, context) {
    super(props);
    this.getComments = this.getComments.bind(this);
    this.addComment = this.addComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      body: "",
      comments: [],
      hasMore: false,
      lastVisible: null,
      initialLoad: false,
      addLoading: false,
      loadingMore: false,
    };
  }

  componentDidMount() {
    this.setState({ initialLoad: true });
    this.getComments().then(() => {
      this.setState({ initialLoad: false });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async addComment() {
    this.setState({ addLoading: true });
    if (this.context && this.context.uid) {
      try {
        const comment = await createComment(
          this.context.uid,
          this.props.lookId,
          this.state.body,
          null,
          0
        );
        this.setState((prevState) => ({
          comments: [...prevState.comments, comment],
        }));
      } catch (err) {
        Sentry.captureException(err);
      }
    }
    this.setState({ addLoading: false });
  }

  async deleteComment(id, remove) {
    if (this.context && this.context.uid) {
      try {
        await deleteComment(id, this.props.lookId);
        if (remove) {
          var items = [...this.state.comments]; // make a separate copy of the array
          const index = this.state.comments.findIndex((item) => item.id === id);
          if (index !== -1) {
            items.splice(index, 1);
            this.setState((prevState) => ({ comments: items }));
          }
        }
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  }

  async getComments() {
    this.setState({ loadingMore: true });
    const queryResult = await getComments(
      this.props.lookId,
      this.state.lastVisible
    );

    this.setState((prevState) => ({
      comments: [...prevState.comments, ...queryResult.comments],
      lastVisible: queryResult.lastVisible,
      hasMore: queryResult.hasMore,
    }));
    this.setState({ loadingMore: false });
  }

  render() {
    return (
      <>
        <Divider horizontal>
          <Header as="h4">Comments</Header>
        </Divider>
        <SemanticComment.Group className="full-width">
          {this.state.initialLoad && (
            <Loader active inline="centered" size="massive" />
          )}
          {this.state.comments.map((comment) => (
            <Comment
              key={comment.id}
              id={comment.id}
              lookId={this.props.lookId}
              userId={comment.user.id}
              username={comment.user.username}
              image={comment.user.signedImage}
              createdAt={comment.createdAt}
              body={comment.body}
              deleteComment={this.deleteComment}
            />
          ))}
          {this.state.hasMore && (
            <Button
              disabled={this.state.loadingMore}
              loading={this.state.loadingMore}
              className="top-buffer"
              onClick={this.getComments}
              content="Load More"
            />
          )}
          {this.context && (
            <Form reply>
              <Form.TextArea
                name="body"
                value={this.state.body}
                onChange={this.handleInputChange}
              />
              <Button
                disabled={this.state.addLoading}
                loading={this.state.addLoading}
                onClick={this.addComment}
                content="Add Comment"
                labelPosition="left"
                icon="edit"
                primary
              />
            </Form>
          )}
        </SemanticComment.Group>
      </>
    );
  }
}

CommentGroup.contextType = UserContext;

export default CommentGroup;
