import React from "react";
import { UserContext } from "../contexts/UserContext";
import { Form, Button, Divider, Message, Loader } from "semantic-ui-react";
import { getUser, updateUserFullName } from "../util/DbHelper.js";

class UpdateAccount extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitAccountChange = this.submitAccountChange.bind(this);
    this.getAccountAttributes = this.getAccountAttributes.bind(this);
    this.submitAccountChange = this.submitAccountChange.bind(this);
    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      error: false,
      success: false,
      errors: [],
    };
  }

  componentDidMount() {
    this.getAccountAttributes();
  }

  async getAccountAttributes() {
    if (this.context && this.context.uid) {
      this.setState({ loading: true });
      const user = await getUser(this.context.uid);
      this.setState({
        firstName: user.firstName,
        lastName: user.lastName,
        gender: user.gender,
        loading: false,
      });
    }
  }

  async updateAccount() {
    if (this.context && this.context.uid) {
      try {
        await updateUserFullName(
          this.context.uid,
          this.state.firstName,
          this.state.lastName
        );
        this.setState({
          error: false,
          success: true,
        });
      } catch (e) {
        this.setState({
          error: true,
          success: false,
        });
      }
    }
  }

  async submitAccountChange() {
    this.setState({ loading: true, error: false, success: false });
    if (!this.state.firstName || !this.state.lastName)
      this.setState({
        error: true,
        success: false,
        errors: ["Please provide name"],
      });
    else await this.updateAccount();
    this.setState({ loading: false });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <>
        <Divider horizontal>Account</Divider>
        {this.state.loading && (
          <Loader active inline="centered" size="massive" />
        )}
        {!this.state.loading && (
          <Form error={this.state.error} success={this.state.success}>
            <Message success header="Success" content="Account updated" />
            <Message error>
              <Message.Header>Error</Message.Header>
              <Message.List>
                {this.state.errors.map((rule, index) => (
                  <Message.Item key={index}>{rule}</Message.Item>
                ))}
              </Message.List>
            </Message>
            <Form.Field>
              <label>First Name</label>
              <input
                type="text"
                onChange={this.handleInputChange}
                value={this.state.firstName}
                placeholder="First Name"
                name="firstName"
              />
            </Form.Field>
            <Form.Field>
              <label>Last Name</label>
              <input
                type="text"
                onChange={this.handleInputChange}
                value={this.state.lastName}
                placeholder="Last Name"
                name="lastName"
              />
            </Form.Field>
            <Button
              loading={this.state.loading}
              type="submit"
              onClick={this.submitAccountChange}
            >
              Save
            </Button>
          </Form>
        )}
      </>
    );
  }
}

UpdateAccount.contextType = UserContext;

export default UpdateAccount;
