import React from "react";
import { Grid, Loader } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import RelatedUserSearch from "./RelatedUserSearch.js";
import { getFollowers, getNoFollowers, getNoLooks } from "../util/DbHelper.js";

class UserStatistics extends React.Component {
  render() {
    return (
      <Grid textAlign="center">
        <Grid.Row columns={2} divided>
          <Grid.Column textAlign="center">
            <div>Looks</div>
            <div>{this.props.noLooks ? this.props.noLooks : 0}</div>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <div>Followers</div>
            <RelatedUserSearch
              userId={this.props.id}
              query={getFollowers}
              display={
                <div className="clickable">{this.props.noFollowers}</div>
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

UserStatistics.contextType = UserContext;

export default UserStatistics;
