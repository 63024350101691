import React from "react";
import LookCard from "./LookCard.js";
import Ad from "../util/Ad.js";
import { UserContext } from "../contexts/UserContext";
import Masonry from "react-masonry-css";

class CardGroup extends React.Component {
  render() {
    return (
      <Masonry
        breakpointCols={this.props.noColumns}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {this.props.looks.map((item, index) => (
          <React.Fragment key={item.id}>
            <div>
              <LookCard
                className="masonry-item"
                look={item}
                images={item.signedImages}
              />
            </div>
          </React.Fragment>
        ))}
      </Masonry>
    );
  }
}

CardGroup.contextType = UserContext;

export default CardGroup;
