import React from "react";
import { Label } from "semantic-ui-react";

class CategoryChip extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.categoryChange(event, "categories");
  }

  render() {
    const opacity = this.props.active ? 1 : 0.4;
    return (
      <>
        <Label
          id={this.props.id}
          onClick={this.handleChange}
          color={this.props.categoryColour}
          size="large"
          style={{ marginBottom: "5px", opacity: opacity, cursor: "pointer" }}
        >
          {this.props.category}
        </Label>
      </>
    );
  }
}

export default CategoryChip;
