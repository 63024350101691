import React from "react";
import LookItemTable from "./LookItemTable.js";

class LookItemTableGroup extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      body: true,
      legs: true,
      feet: true,
      head: true,
      accessory: true,
    };
    this.hideGroup = this.hideGroup.bind(this);
  }

  hideGroup(type) {
    this.setState({ [type]: false });
  }

  render() {
    return (
      <>
        {this.state.body && (
          <LookItemTable
            lookId={this.props.lookId}
            type="body"
            hideGroup={this.hideGroup}
          />
        )}
        {this.state.legs && (
          <LookItemTable
            lookId={this.props.lookId}
            type="legs"
            hideGroup={this.hideGroup}
          />
        )}
        {this.state.feet && (
          <LookItemTable
            lookId={this.props.lookId}
            type="feet"
            hideGroup={this.hideGroup}
          />
        )}
        {this.state.head && (
          <LookItemTable
            lookId={this.props.lookId}
            type="head"
            hideGroup={this.hideGroup}
          />
        )}
        {this.state.accessory && (
          <LookItemTable
            lookId={this.props.lookId}
            type="accessory"
            hideGroup={this.hideGroup}
          />
        )}
      </>
    );
  }
}

export default LookItemTableGroup;
