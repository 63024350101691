import React from "react";
import SignIn from "./SignIn.js";
import SignUp from "./SignUp.js";
import { Grid, Menu, Container, Segment } from "semantic-ui-react";
import { DeviceContext } from "../contexts/DeviceContext";
import { UserContext } from "../contexts/UserContext";
import { Redirect } from "react-router-dom";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeItem: "Sign In" };
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(e, { name }) {
    this.setState({ activeItem: name });
  }

  render() {
    let activePage;
    if (this.state.activeItem === "Sign In") {
      activePage = <SignIn />;
    } else {
      activePage = <SignUp />;
    }

    return (
      <DeviceContext.Consumer>
        {(device) => (
          <>
            {!this.context && (
              <Container>
                <Grid>
                  <Grid.Column
                    width={device.type === "mobile" ? 1 : 5}
                  ></Grid.Column>
                  <Grid.Column width={device.type === "mobile" ? 14 : 6}>
                    <Segment.Group>
                      <Segment.Group horizontal>
                        <Menu pointing>
                          <Menu.Item
                            name="Sign In"
                            active={this.state.activeItem === "Sign In"}
                            onClick={this.handleItemClick}
                          />
                          <Menu.Item
                            name="Sign Up"
                            active={this.state.activeItem === "Sign Up"}
                            onClick={this.handleItemClick}
                          />
                        </Menu>
                      </Segment.Group>
                      <Segment compact>{activePage}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                  <Grid.Column
                    width={device.type === "mobile" ? 1 : 5}
                  ></Grid.Column>
                </Grid>
              </Container>
            )}
            {this.context && <Redirect to="/discover" />}
          </>
        )}
      </DeviceContext.Consumer>
    );
  }
}

Login.contextType = UserContext;

export default Login;
