import React from "react";
import InfiniteFeed from "../util/InfiniteFeed.js";
import { Grid, Loader } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import { DeviceContext } from "../contexts/DeviceContext";
import { getPinnedLooks } from "../util/DbHelper.js";
import * as Sentry from "@sentry/react";

class Pins extends React.Component {
  constructor(props, context) {
    super(props);
    this.loadLooks = this.loadLooks.bind(this);
    this.state = {
      loading: false,
      error: false,
      hasMore: true,
      lastVisible: null,
      looks: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadLooks();
  }

  async loadLooks() {
    try {
      const result = await getPinnedLooks(
        this.context.uid,
        this.state.lastVisible
      );

      this.setState((prevState) => ({
        looks: [...prevState.looks, ...result.looks],
        lastVisible: result.lastVisible,
        hasMore: result.hasMore,
      }));
    } catch (err) {
      Sentry.captureException(err);
      this.setState({ error: true });
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <DeviceContext.Consumer>
        {(device) => (
          <>
            {this.state.loading && (
              <Loader active inline="centered" size="massive" />
            )}
            {!this.state.loading && (
              <Grid
                className="no-margin-x"
                centered
                columns={device.type === "wide screen" ? 2 : 1}
              >
                <Grid.Column className="no-padding-x">
                  <InfiniteFeed
                    loadLooks={this.loadLooks}
                    looks={this.state.looks}
                    hasMore={this.state.hasMore}
                    error={this.state.error}
                    noColumns={device.type === "mobile" ? 1 : 2}
                  />
                </Grid.Column>
              </Grid>
            )}
          </>
        )}
      </DeviceContext.Consumer>
    );
  }
}

Pins.contextType = UserContext;

export default Pins;
