import React from "react";
import {
  Button,
  Form,
  Message,
  Grid,
  Container,
  Segment,
} from "semantic-ui-react";
import { withRouter } from "react-router";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { DeviceContext } from "../contexts/DeviceContext";

class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errors: [],
      error: false,
    };
    this.resendConfirmationCode = this.resendConfirmationCode.bind(this);
  }

  resendConfirmationCode() {
    this.setState({ error: false, errors: [], loading: true });
    const auth = getAuth();
    const user = auth.currentUser;
    sendEmailVerification(user).catch((error) => {
      this.setState({
        error: true,
        errors: [`Could not resend confirmation`],
      });
    }).finally(() => {
      this.setState({
        loading: false
      });
    })
  }

  render() {
    return (
      <DeviceContext.Consumer>
        {(device) => (
          <Container>
            <Grid>
              <Grid.Column
                width={device.type === "mobile" ? 1 : 5}
              ></Grid.Column>
              <Grid.Column width={device.type === "mobile" ? 14 : 6}>
                <Segment compact>
                  <Form error={this.state.error}>
                    <Message error>
                      <Message.Header>Error</Message.Header>
                      <Message.List>
                        {this.state.errors.map((rule, index) => (
                          <Message.Item key={index}>{rule}</Message.Item>
                        ))}
                      </Message.List>
                    </Message>
                    <Form.Field>
                      <label>
                        Please follow the verification link sent to your email
                        address.
                      </label>
                    </Form.Field>
                    <Button
                      onClick={this.resendConfirmationCode}
                      onKeyPress={this.resendConfirmationCode}
                      loading={this.state.loading}
                      disabled={this.state.loading}
                    >
                      Resend
                    </Button>
                  </Form>
                </Segment>
              </Grid.Column>
              <Grid.Column
                width={device.type === "mobile" ? 1 : 5}
              ></Grid.Column>
            </Grid>
          </Container>
        )}
      </DeviceContext.Consumer>
    );
  }
}

export default withRouter(ConfirmEmail);
