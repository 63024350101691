import React from "react";
import { Route, Redirect } from "react-router-dom";

class PrivateRoute extends React.Component {
  render() {
    return (
      <Route>
        {this.props.user ? this.props.children : <Redirect to="/login" />}
      </Route>
    );
  }
}

export default PrivateRoute;
