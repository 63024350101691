import React from "react";
import "./App.css";
import Login from "./login/Login.js";
import ConfirmEmail from "./login/ConfirmEmail.js";
import Header from "./header/Header.js";
import SetupUser from "./login/SetupUser.js";
import NewLook from "./new-look/NewLook.js";
import Pins from "./pins/Pins.js";
import ForgotPassword from "./login/ForgotPassword.js";
import User from "./user/User.js";
import Look from "./look/Look.js";
import Discover from "./discover/Discover.js";
import Account from "./account/Account.js";
import CookieConsentModal from "./util/CookieConsentModal.js";
import "semantic-ui-css/semantic.min.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { UserContext } from "./contexts/UserContext";
import { DeviceContext } from "./contexts/DeviceContext";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import PrivateRoute from "./util/PrivateRoute.js";
import { userExists, getUser } from "./util/DbHelper.js";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBq7LgG-lMLrbLvdFLTb21G3Xsvl-98p10",
  authDomain: "that-getup.firebaseapp.com",
  projectId: "that-getup",
  storageBucket: "that-getup.appspot.com",
  messagingSenderId: "325557501109",
  appId: "1:325557501109:web:06bbe2296a0be76fbed2fc",
  measurementId: "G-VR1RMH5WDW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Sentry.init({
  dsn: "https://2c68724b71d14ad0b173b6121aa58f6c@o995288.ingest.sentry.io/5954127",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      authenticated: false,
      verified: false,
      device: {
        type: "",
        width: 0,
        height: 0,
      },
    };
    this.setupAuthListener = this.setupAuthListener.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.setupUserCreationListener = this.setupUserCreationListener.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    let type;
    if (window.innerWidth < 993) type = "mobile";
    else if (window.innerWidth < 1500) type = "computer";
    else type = "wide screen";
    this.setState({
      device: {
        type: type,
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }

  componentDidMount() {
    this.setupAuthListener();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  async setupAuthListener() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const isUserSetup = await userExists(user.uid);
        if (!isUserSetup) this.setupUserCreationListener(user.uid);
        else user.profile = await getUser(user.uid);

        if (user.emailVerified)
          this.setState({
            user: user,
            authenticated: true,
            verified: true,
            isUserSetup: isUserSetup,
          });
        else
          this.setState({
            user: user,
            authenticated: true,
            verified: false,
            isUserSetup: isUserSetup,
          });
      } else {
        this.setState({ user: null, authenticated: true, verified: false });
      }
    });
  }

  setupUserCreationListener(uid) {
    const db = getFirestore();
    const unsub = onSnapshot(doc(db, "User", uid), (doc) => {
      const userProfile = doc.data();
      if (userProfile) {
        let user = this.state.user;
        user.profile = userProfile;
        this.setState({ user: user, isUserSetup: true });
      }
    });
  }

  render() {
    return (
      <>
        <UserContext.Provider value={this.state.user}>
          <DeviceContext.Provider value={this.state.device}>
            <Router>
              <Route path="/">
                <Header />
              </Route>
              {this.state.authenticated &&
                this.state.user &&
                !this.state.verified && (
                  <Route path="/">
                    <ConfirmEmail />
                  </Route>
                )}
              {this.state.authenticated &&
                this.state.user &&
                this.state.verified &&
                !this.state.isUserSetup && (
                  <Route path="/">
                    <SetupUser />
                  </Route>
                )}
              {this.state.authenticated &&
                this.state.user &&
                this.state.verified &&
                this.state.isUserSetup && (
                  <Switch>
                    <PrivateRoute user={this.state.user} path="/account">
                      <Account />
                    </PrivateRoute>
                    <PrivateRoute user={this.state.user} path="/new-look">
                      <NewLook />
                    </PrivateRoute>
                    <PrivateRoute user={this.state.user} path="/pins">
                      <Pins />
                    </PrivateRoute>
                    <Route path="/discover">
                      <Discover />
                    </Route>
                    <Route path="/login">
                      <Login />
                    </Route>
                    <Route path="/forgot-password">
                      <ForgotPassword />
                    </Route>
                    <Route path="/look/:id">
                      <Look />
                    </Route>
                    <Route path="/user/:id">
                      <User />
                    </Route>
                    <Route path="/">
                      <Redirect to="/discover" />
                    </Route>
                  </Switch>
                )}
              {this.state.authenticated && !this.state.user && (
                <Switch>
                  <PrivateRoute user={this.state.user} path="/account">
                    <Account />
                  </PrivateRoute>
                  <PrivateRoute user={this.state.user} path="/new-look">
                    <NewLook />
                  </PrivateRoute>
                  <PrivateRoute user={this.state.user} path="/pins">
                    <Pins />
                  </PrivateRoute>
                  <Route path="/discover">
                    <Discover />
                  </Route>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/forgot-password">
                    <ForgotPassword />
                  </Route>
                  <Route path="/look/:id">
                    <Look />
                  </Route>
                  <Route path="/user/:id">
                    <User />
                  </Route>
                  <Route path="/">
                    <Redirect to="/discover" />
                  </Route>
                </Switch>
              )}
            </Router>
            <CookieConsentModal />
          </DeviceContext.Provider>
        </UserContext.Provider>
      </>
    );
  }
}

export default App;
