import React from "react";
import {
  Button,
  Form,
  Message,
  Grid,
  Container,
  Segment,
} from "semantic-ui-react";
import { withRouter } from "react-router";
import { DeviceContext } from "../contexts/DeviceContext";
import { UserContext } from "../contexts/UserContext";
import { createUser, usernameAvailable } from "../util/DbHelper.js";

class SetupUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      username: "",
      loading: false,
      error: false,
      gender: "ALL",
      errors: [],
      typingTimer: null,
      availableUsername: null,
    };
    this.setupUser = this.setupUser.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.displayUsernameAvailability =
      this.displayUsernameAvailability.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleKeyDown() {
    clearTimeout(this.state.typingTimer);
  }

  async handleKeyUp() {
    clearTimeout(this.state.typingTimer);
    let typingTimer = setTimeout(this.displayUsernameAvailability, 1000);
    this.setState({ typingTimer: typingTimer, availableUsername: null });
  }

  async displayUsernameAvailability() {
    this.setState({
      availableUsername: await usernameAvailable(this.state.username),
    });
  }

  async setupUser() {
    this.setState({ loading: true, error: false });

    const valid = await this.validateForm();
    if (!valid) this.setState({ error: true });
    else {
      try {
        await createUser(
          this.context.uid,
          this.state.username,
          this.state.firstName,
          this.state.lastName,
          this.state.gender
        );
      } catch (e) {
        this.setState({ error: true, errors: ["Could not create user."] });
      }
    }

    this.setState({ loading: false });
  }

  async validateForm() {
    let errors = [];
    let formIsValid = true;

    if (!this.state.username) {
      formIsValid = false;
      errors.push("Please provde a username");
    }
    if (
      this.state.username &&
      !(await usernameAvailable(this.state.username))
    ) {
      formIsValid = false;
      errors.push("Username is already taken");
    }
    if (
      this.state.username &&
      !/^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/.test(
        this.state.username
      )
    ) {
      formIsValid = false;
      errors.push(
        "Username must only contain letters, numbers, underscores and fullstops"
      );
      errors.push("Maximum username length is 30 characters");
    }
    if (!this.state.firstName || !this.state.lastName) {
      formIsValid = false;
      errors.push("Please provide name");
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    return (
      <DeviceContext.Consumer>
        {(device) => (
          <Container>
            <Grid>
              <Grid.Column
                width={device.type === "mobile" ? 1 : 5}
              ></Grid.Column>
              <Grid.Column width={device.type === "mobile" ? 14 : 6}>
                <Segment>
                  <Form error={this.state.error}>
                    <Message error>
                      <Message.Header>Error</Message.Header>
                      <Message.List>
                        {this.state.errors.map((rule, index) => (
                          <Message.Item key={index}>{rule}</Message.Item>
                        ))}
                      </Message.List>
                    </Message>
                    <Form.Field>
                      <label>First Name</label>
                      <input
                        type="text"
                        onChange={this.handleInputChange}
                        value={this.state.firstName}
                        placeholder="First Name"
                        name="firstName"
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Last Name</label>
                      <input
                        type="text"
                        onChange={this.handleInputChange}
                        value={this.state.lastName}
                        placeholder="Last Name"
                        name="lastName"
                      />
                    </Form.Field>
                    <Form.Field error={this.state.availableUsername === false}>
                      <label>Username</label>
                      <input
                        className={
                          this.state.username &&
                          this.state.availableUsername !== null &&
                          this.state.availableUsername
                            ? "successField"
                            : ""
                        }
                        type="text"
                        onChange={this.handleInputChange}
                        onKeyUp={this.handleKeyUp}
                        onKeyDown={this.handleKeyDown}
                        value={this.state.username}
                        placeholder="Username"
                        name="username"
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>What looks would you like to see?</label>
                      <select
                        onChange={this.handleInputChange}
                        value={this.state.gender}
                        placeholder="Gender"
                        name="gender"
                      >
                        <option value="ALL">All</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="OTHER">Other</option>
                      </select>
                    </Form.Field>
                    <Button
                      onClick={this.setupUser}
                      onKeyPress={this.setupUser}
                      loading={this.state.loading}
                      disabled={this.state.loading}
                    >
                      Complete Sign Up
                    </Button>
                  </Form>
                </Segment>
              </Grid.Column>
              <Grid.Column
                width={device.type === "mobile" ? 1 : 5}
              ></Grid.Column>
            </Grid>
          </Container>
        )}
      </DeviceContext.Consumer>
    );
  }
}

SetupUser.contextType = UserContext;

export default withRouter(SetupUser);
