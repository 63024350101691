import React from "react";
import InfiniteFeed from "../util/InfiniteFeed.js";
import Profile from "./Profile.js";
import { Segment, Grid, Loader } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import { DeviceContext } from "../contexts/DeviceContext";
import { withRouter } from "react-router";
import { getLooksByUser, getUser } from "../util/DbHelper.js";
import * as Sentry from "@sentry/react";

class User extends React.Component {
  constructor(props, context) {
    super(props);
    this.getUser = this.getUser.bind(this);
    this.userCheck = this.userCheck.bind(this);
    this.loadLooks = this.loadLooks.bind(this);
    this.state = {
      loading: true,
      loadingFeed: true,
      hasMore: true,
      user: null,
      foundUser: null,
      ownPage: false,
      lastVisible: null,
      looks: [],
    };
  }

  userCheck() {
    const ownPage =
      this.context &&
      this.context.uid &&
      this.props.match.params.id === this.context.uid;
    this.setState(
      { ownPage: ownPage, user: null, loading: true },
      this.getUser
    );
  }

  componentDidMount() {
    this.userCheck();
    this.loadLooks();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.userCheck();
      this.setState(
        { looks: [], lastVisible: null, hasMore: true },
        this.loadLooks
      );
    }
  }

  async getUser() {
    const user = await getUser(this.props.match.params.id);
    if (user) {
      this.setState({
        user: user,
        loading: false,
      });
    } else {
      this.setState({ loading: false });
    }
  }

  async loadLooks() {
    try {
      const result = await getLooksByUser(
        this.props.match.params.id,
        this.state.lastVisible
      );

      this.setState((prevState) => ({
        looks: [...prevState.looks, ...result.looks],
        lastVisible: result.lastVisible,
        hasMore: result.hasMore,
      }));
    } catch (err) {
      Sentry.captureException(err);
      this.setState({ error: true });
    }
    this.setState({ loadingFeed: false });
  }

  render() {
    return (
      <DeviceContext.Consumer>
        {(device) => (
          <>
            {this.state.loading && (
              <Loader active inline="centered" size="massive" />
            )}
            {!this.state.loading && !this.state.user && (
              <Grid centered columns={3}>
                <Grid.Column>
                  <Segment>Could not find user.</Segment>
                </Grid.Column>
              </Grid>
            )}
            {!this.state.loading && this.state.user && (
              <>
                <Grid
                  className="no-margin-x"
                  centered
                  columns={
                    device.type === "mobile"
                      ? 1
                      : device.type === "computer"
                      ? 2
                      : device.type === "wide screen"
                      ? 3
                      : 3
                  }
                >
                  <Grid.Column className="no-padding-x">
                    <Profile
                      user={this.state.user}
                      ownPage={this.state.ownPage}
                    />
                  </Grid.Column>
                </Grid>
                <Grid
                  className="no-margin-x"
                  centered
                  columns={device.type === "wide screen" ? 2 : 1}
                >
                  <Grid.Column className="no-padding-x">
                    {this.state.loadingFeed && (
                      <Loader active inline="centered" size="massive" />
                    )}
                    {!this.state.loadingFeed && (
                      <InfiniteFeed
                        loadLooks={this.loadLooks}
                        looks={this.state.looks}
                        hasMore={this.state.hasMore}
                        error={this.state.error}
                        noColumns={device.type === "mobile" ? 1 : 2}
                      />
                    )}
                  </Grid.Column>
                </Grid>
              </>
            )}
          </>
        )}
      </DeviceContext.Consumer>
    );
  }
}

User.contextType = UserContext;

export default withRouter(User);
