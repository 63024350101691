import React from "react";
import { Icon } from "semantic-ui-react";
import { Grid } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import { createLike, deleteLike, likeExists } from "../util/DbHelper.js";

class LikeButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { like: false, increment: 0 };
    this.like = this.like.bind(this);
    this.unlike = this.unlike.bind(this);
    this.islike = this.islike.bind(this);
  }

  componentDidMount() {
    this.islike();
  }

  async islike() {
    if (this.context && this.context.uid) {
      const like = await likeExists(this.context.uid, this.props.id);
      this.setState({ like: like });
    }
  }

  async like() {
    if (this.context && this.context.uid) {
      await createLike(this.context.uid, this.props.id);
      this.setState({ like: true, increment: 1 });
    }
  }

  async unlike() {
    if (this.context && this.context.uid) {
      await deleteLike(this.context.uid, this.props.id);
      this.setState({ like: false, increment: -1 });
    }
  }

  render() {
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} textAlign="left">
              {!this.state.like && (
                <>
                  <Icon
                    className="clickable"
                    name="like"
                    color="grey"
                    onClick={this.like}
                  />
                  {this.props.noLikes &&
                    this.props.noLikes + this.state.increment}
                </>
              )}
              {this.state.like && (
                <>
                  <Icon
                    className="clickable"
                    name="like"
                    color="red"
                    onClick={this.unlike}
                  />
                  {this.props.noLikes &&
                    this.props.noLikes + this.state.increment}
                </>
              )}
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Icon className="clickable" color="grey" name="comment" />
              {this.props.noComments}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

LikeButton.contextType = UserContext;

export default LikeButton;
