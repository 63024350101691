import React from "react";
import { Button, Form, Message } from "semantic-ui-react";
import { withRouter } from "react-router";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      error: false,
      errors: [],
      loading: false,
    };
    this.signUp = this.signUp.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitSignUp = this.submitSignUp.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  async signUp() {
    const auth = getAuth();
    createUserWithEmailAndPassword(
      auth,
      this.state.email.trim(),
      this.state.password
    )
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        sendEmailVerification(user);
      })
      .catch((error) => {
        this.setState({
          error: true,
          errors: [`Signup failed`],
        });
      }).finally(() => {
        this.setState({ loading: false });
      })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async submitSignUp() {
    this.setState({ loading: true, error: false });

    const valid = this.validateForm();
    if (!valid) this.setState({ error: true, loading: false });
    else await this.signUp();
  }

  validateForm() {
    let errors = [];
    let formIsValid = true;

    if (!this.state.password) {
      formIsValid = false;
      errors.push("Please provde a password");
    }
    if (
      this.state.password &&
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/.test(
        this.state.password
      )
    ) {
      formIsValid = false;
      errors.push("Password requires number.");
      errors.push("Password requires special character");
      errors.push("Password require uppercase letter");
      errors.push("Password require lowercase letter");
      errors.push("Minimum password length 8");
    }
    if (
      (this.state.password && !this.state.confirmPassword) ||
      (!this.state.password && this.state.confirmPassword)
    ) {
      formIsValid = false;
      errors.push("Please confirm password");
    }
    if (
      this.state.password &&
      this.state.confirmPassword &&
      this.state.password !== this.state.confirmPassword
    ) {
      formIsValid = false;
      errors.push("Passwords do not match");
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    return (
      <Form error={this.state.error}>
        <Message error>
          <Message.Header>Error</Message.Header>
          <Message.List>
            {this.state.errors.map((rule, index) => (
              <Message.Item key={index}>{rule}</Message.Item>
            ))}
          </Message.List>
        </Message>
        <Form.Field>
          <label>Email</label>
          <input
            type="email"
            onChange={this.handleInputChange}
            value={this.state.email}
            placeholder="Email"
            name="email"
          />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input
            type="password"
            onChange={this.handleInputChange}
            value={this.state.password}
            placeholder="Password"
            name="password"
          />
        </Form.Field>
        <Form.Field>
          <label>Confirm Password</label>
          <input
            type="password"
            onChange={this.handleInputChange}
            value={this.state.confirmPassword}
            placeholder="Confirm Password"
            name="confirmPassword"
          />
        </Form.Field>
        <Button
          onClick={this.submitSignUp}
          onKeyPress={this.submitSignUp}
          loading={this.state.loading}
          disabled={this.state.loading}
        >
          Sign Up
        </Button>
      </Form>
    );
  }
}

export default withRouter(SignUp);
