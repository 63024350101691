import React from "react";
import NewLookItem from "./NewLookItem.js";
import { Divider, Header, Container, Icon } from "semantic-ui-react";

class NewLookItemTable extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.addItem(this.props.itemType);
  }

  render() {
    const items = this.props.items.map((item, index) => (
      <NewLookItem
        onItemImageUpload={this.props.onItemImageUpload}
        key={item.uuid}
        item={item}
        updateItem={this.props.updateItem}
        deleteItem={this.props.deleteItem}
      />
    ));

    return (
      <>
        <Divider horizontal>
          <Header as="h4">{this.props.itemType}</Header>
        </Divider>
        {items}
        <Container textAlign="center">
          <Icon
            id="body"
            className="center top-buffer bottom-buffer"
            size="big"
            name="add circle"
            onClick={this.handleChange}
          />
        </Container>
      </>
    );
  }
}

export default NewLookItemTable;
