import React from "react";
import { Button, Form, Message } from "semantic-ui-react";
import { updateUserBio } from "../util/DbHelper.js";
import { UserContext } from "../contexts/UserContext";
import * as Sentry from "@sentry/react";

class BioEditor extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.state = {
      editBio: this.props.bio ? this.props.bio : "",
      error: false,
      loading: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "editBio" && value.length > 500)
      value = value.substring(0, 500);

    this.setState({
      [name]: value,
    });
  }

  async saveChanges() {
    if (this.context && this.context.uid) {
      this.setState({ loading: true });

      try {
        await updateUserBio(this.context.uid, this.state.editBio);
        this.props.updateBio(this.state.editBio);
      } catch (err) {
        Sentry.captureException(err);
        this.setState({ error: true, loading: false });
      }

      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <>
        <Form error={this.state.error}>
          <Message error>
            <Message.Header>Error</Message.Header>
            <p>Could not update bio.</p>
          </Message>
          <Form.Field
            control="textarea"
            rows="3"
            onChange={this.handleInputChange}
            name="editBio"
            value={this.state.editBio}
          />
          <p className="char-count">{this.state.editBio.length}/500</p>
        </Form>
        <Button
          secondary
          loading={this.state.loading}
          onClick={this.saveChanges}
        >
          Save
        </Button>
        <Button secondary floated="right" onClick={this.props.cancelEditBio}>
          Cancel
        </Button>
      </>
    );
  }
}

BioEditor.contextType = UserContext;

export default BioEditor;
