import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <p>
        This Privacy Policy explains how we collect, use, and protect your
        personal information when you visit our website. By using our website,
        you consent to the terms of this Privacy Policy.
      </p>

      <h3>Information We Collect</h3>
      <p>
        We may collect various types of personal information when you interact
        with our website, including:
      </p>
      <ul>
        <li>
          Your name, email address, and other contact details provided through
          forms or account registration
        </li>
        <li>Information collected through cookies and similar technologies</li>
        <li>
          Log files, which may include IP addresses, browser type, internet
          service provider, referring/exit pages, and timestamps
        </li>
      </ul>

      <h3>How We Use Your Information</h3>
      <p>
        We may use the personal information we collect for various purposes,
        including:
      </p>
      <ul>
        <li>Providing and improving our services</li>
        <li>Communicating with you and responding to your inquiries</li>
        <li>Personalizing your experience on our website</li>
        <li>Analytics and performance monitoring</li>
        <li>Complying with legal and regulatory requirements</li>
      </ul>

      <h3>Disclosure of Your Information</h3>
      <p>
        We may share your personal information with third parties in the
        following circumstances:
      </p>
      <ul>
        <li>With your consent</li>
        <li>
          To our service providers and partners who assist us in operating our
          website and providing our services
        </li>
        <li>
          To comply with legal obligations or protect our rights and safety
        </li>
        <li>
          In connection with a business transaction, such as a merger,
          acquisition, or sale of assets
        </li>
      </ul>

      <h3>Data Security</h3>
      <p>
        We take reasonable measures to protect the security of your personal
        information and prevent unauthorized access or disclosure. However, no
        data transmission over the internet or storage system can be guaranteed
        to be 100% secure. We cannot ensure or warrant the security of any
        information you transmit to us.
      </p>

      <h3>Retention of Your Information</h3>
      <p>
        We retain your personal information for as long as necessary to fulfill
        the purposes outlined in this Privacy Policy, unless a longer retention
        period is required or permitted by law.
      </p>

      <h3>Your Rights</h3>
      <p>
        You have certain rights regarding your personal information, including
        the right to access, correct, or delete your data. If you wish to
        exercise any of these rights, please contact us using the information
        provided below.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions or concerns about our Privacy Policy or the
        handling of your personal information, please contact us.
      </p>
    </>
  );
};

export default PrivacyPolicy;
