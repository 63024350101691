import React from "react";
import { Button, Form, Grid, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ConfirmEmail from "./ConfirmEmail";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { withRouter } from "react-router";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: [],
      error: false,
      confirmationSent: false,
      loading: false,
    };
    this.signIn = this.signIn.bind(this);
    this.submitSignIn = this.submitSignIn.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  signIn() {
    const auth = getAuth();
    signInWithEmailAndPassword(
      auth,
      this.state.email.trim(),
      this.state.password
    )
      .then((userCredential) => {
        this.props.history.push("/discover");
      })
      .catch((error) => {
        this.setState({
          error: true,
          errors: [`Sign in failed: please ensure your username and password are correct`],
        });
      }).finally(() => {
        this.setState({ loading: false });
      })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  validateForm() {
    let errors = [];
    let formIsValid = true;

    if (!this.state.email) {
      formIsValid = false;
      errors.push("Please enter your email");
    }
    if (!this.state.password) {
      formIsValid = false;
      errors.push("Please enter your password");
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  submitSignIn() {
    this.setState({ error: false, loading: true });

    if (!this.validateForm()) this.setState({ error: true, loading: false });
    else this.signIn();
  }

  render() {
    return (
      <>
        {!this.state.confirmationSent && (
          <Form error={this.state.error}>
            <Message error>
              <Message.Header>Error</Message.Header>
              <Message.List>
                {this.state.errors.map((rule, index) => (
                  <Message.Item key={index}>{rule}</Message.Item>
                ))}
              </Message.List>
            </Message>
            <Form.Field>
              <label>Email</label>
              <input
                type="email"
                onChange={this.handleInputChange}
                value={this.state.email}
                placeholder="Email"
                name="email"
              />
            </Form.Field>
            <Form.Field>
              <label>Password</label>
              <input
                type="password"
                onChange={this.handleInputChange}
                value={this.state.password}
                placeholder="Password"
                name="password"
              />
            </Form.Field>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Button
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    onClick={this.submitSignIn}
                    onKeyPress={this.submitSignIn}
                  >
                    Sign In
                  </Button>
                </Grid.Column>
                <Grid.Column textAlign="right" verticalAlign="middle">
                  <Link to="/forgot-password">Forgot Password</Link>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
        {this.state.confirmationSent && (
          <ConfirmEmail
            email={this.state.email}
            password={this.state.password}
          />
        )}
      </>
    );
  }
}

export default withRouter(SignIn);
