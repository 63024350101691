import React from "react";
import { Menu, Dropdown } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { getAuth, signOut } from "firebase/auth";

class LoginMenu extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = { firstName: "" };
    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }

  render() {
    return (
      <Menu floated="right" secondary>
        {(!this.context || !this.context.uid) && (
          <Menu.Item
            as={NavLink}
            to="/login"
            name="login"
            active={this.props.activeItem === "login"}
            onClick={this.props.handleItemClick}
          />
        )}
        {this.context && this.context.uid && (
          <Dropdown
            item
            text={
              this.context && this.context.profile
                ? this.context.profile.firstName
                : "Menu"
            }
          >
            <Dropdown.Menu>
              <Dropdown.Item as={NavLink} to={"/account"}>
                Account
              </Dropdown.Item>
              <Dropdown.Item onClick={this.signOut}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Menu>
    );
  }
}

LoginMenu.contextType = UserContext;

export default withRouter(LoginMenu);
