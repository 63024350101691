import React from "react";
import NewLookItemTable from "./NewLookItemTable.js";

class NewLookItemTableGroup extends React.Component {
  render() {
    const bodyItems = this.props.items.filter(
      (item) => item.itemType === "body"
    );
    const legItems = this.props.items.filter(
      (item) => item.itemType === "legs"
    );
    const feetItems = this.props.items.filter(
      (item) => item.itemType === "feet"
    );
    const headItems = this.props.items.filter(
      (item) => item.itemType === "head"
    );
    const accessoryItems = this.props.items.filter(
      (item) => item.itemType === "accessory"
    );

    return (
      <>
        <NewLookItemTable
          itemType="body"
          onItemImageUpload={this.props.onItemImageUpload}
          items={bodyItems}
          addItem={this.props.addItem}
          updateItem={this.props.updateItem}
          deleteItem={this.props.deleteItem}
        />
        <NewLookItemTable
          itemType="legs"
          onItemImageUpload={this.props.onItemImageUpload}
          items={legItems}
          addItem={this.props.addItem}
          updateItem={this.props.updateItem}
          deleteItem={this.props.deleteItem}
        />
        <NewLookItemTable
          itemType="feet"
          onItemImageUpload={this.props.onItemImageUpload}
          items={feetItems}
          addItem={this.props.addItem}
          updateItem={this.props.updateItem}
          deleteItem={this.props.deleteItem}
        />
        <NewLookItemTable
          itemType="head"
          onItemImageUpload={this.props.onItemImageUpload}
          items={headItems}
          addItem={this.props.addItem}
          updateItem={this.props.updateItem}
          deleteItem={this.props.deleteItem}
        />
        <NewLookItemTable
          itemType="accessory"
          onItemImageUpload={this.props.onItemImageUpload}
          items={accessoryItems}
          addItem={this.props.addItem}
          updateItem={this.props.updateItem}
          deleteItem={this.props.deleteItem}
        />
      </>
    );
  }
}

export default NewLookItemTableGroup;
