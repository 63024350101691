import React from "react";
import { Button, Icon, Grid } from "semantic-ui-react";

class CopyLinkButton extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = { clicked: false };
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  copyToClipboard() {
    navigator.clipboard.writeText(window.location.href);
    this.setState({ clicked: true });
  }

  render() {
    return (
      <Grid>
        <Grid.Column textAlign="center">
          <Button onClick={this.copyToClipboard}>
            {this.state.clicked ? <Icon name="thumbs up" /> : "Copy Link"}
          </Button>
        </Grid.Column>
      </Grid>
    );
  }
}

export default CopyLinkButton;
