import React from "react";
import FollowButton from "../util/FollowButton.js";
import blankPicture from "../res/blank-profile-picture.png";
import UserStatistics from "./UserStatistics.js";
import AvatarEditor from "./AvatarEditor.js";
import BioEditor from "./BioEditor.js";
import {
  Segment,
  Button,
  Grid,
  Image,
  Icon,
  Dropdown,
} from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import { DeviceContext } from "../contexts/DeviceContext";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

class Profile extends React.Component {
  constructor(props, context) {
    super(props);
    this.editBio = this.editBio.bind(this);
    this.editImage = this.editImage.bind(this);
    this.cancelEditBio = this.cancelEditBio.bind(this);
    this.cancelEditImage = this.cancelEditImage.bind(this);
    this.updateImage = this.updateImage.bind(this);
    this.updateBio = this.updateBio.bind(this);
    const image = this.props.user.signedImage
      ? this.props.user.signedImage
      : blankPicture;
    this.state = {
      image: image,
      bio: this.props.user.bio,
      editBio: false,
      editImage: false,
    };
    console.log(this.props.user);
  }

  updateImage(image) {
    this.setState({ image });
    this.cancelEditImage();
  }

  updateBio(bio) {
    this.setState({ bio });
    this.cancelEditBio();
  }

  cancelEditBio() {
    this.setState({ editBio: false });
  }

  cancelEditImage() {
    this.setState({ editImage: false });
  }

  editBio() {
    this.setState({ editBio: true });
  }

  editImage() {
    this.setState({ editImage: true });
  }

  render() {
    return (
      <DeviceContext.Consumer>
        {(device) => (
          <>
            <Segment>
              {this.state.editBio && (
                <BioEditor
                  key={this.state.bio}
                  bio={this.state.bio}
                  updateBio={this.updateBio}
                  cancelEditBio={this.cancelEditBio}
                />
              )}
              {this.state.editImage && (
                <AvatarEditor
                  updateImage={this.updateImage}
                  cancelEditImage={this.cancelEditImage}
                />
              )}
              {!this.state.editBio && !this.state.editImage && (
                <Grid>
                  {this.props.ownPage && (
                    <Grid.Row columns={2}>
                      <Grid.Column floated="left">
                        <Link to="/new-look">
                          <Button secondary>New Look</Button>
                        </Link>
                      </Grid.Column>
                      <Grid.Column floated="right" textAlign="right">
                        <Dropdown
                          direction="left"
                          icon="ellipsis horizontal"
                          className="icon"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              text="Update profile picture"
                              onClick={this.editImage}
                            />
                            <Dropdown.Item
                              text="Edit bio"
                              onClick={this.editBio}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row centered>
                    <Grid.Column>
                      <Image
                        src={this.state.image}
                        size="medium"
                        circular
                        centered
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered>
                    <Icon className="no-margin" name="at" />
                    {this.props.user.username}
                  </Grid.Row>
                  <UserStatistics
                    noFollowers={this.props.user.followers}
                    noFollowing={this.props.user.following}
                    noLooks={this.props.user.looks}
                    id={this.props.user.id}
                  />
                  {this.context && this.context.uid && !this.props.ownPage && (
                    <Grid.Row centered>
                      <FollowButton id={this.props.user.id} />
                    </Grid.Row>
                  )}
                  <Grid.Row>
                    <Grid.Column>
                      <p className="break-word">{this.state.bio}</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Segment>
          </>
        )}
      </DeviceContext.Consumer>
    );
  }
}

Profile.contextType = UserContext;

export default withRouter(Profile);
