import React from "react";
import FilepondUploader from "../util/FilepondUploader.js";
import { Form, Icon, Grid, Dropdown } from "semantic-ui-react";
import { currencyOptions } from "../res/options.js";

class NewLookItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "https://react.semantic-ui.com/images/wireframe/image.png",
      imageFile: this.props.imageFile,
    };
    this.handleChange = this.handleChange.bind(this);
    this.currencyChange = this.currencyChange.bind(this);
    this.onItemImageUpload = this.onItemImageUpload.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  handleChange(e) {
    let { name, value, min, max } = e.target;
    console.log(value);
    if (name === "cost" && value > 9999999)
      value = Number(value.toString().substring(0, 7));

    this.props.updateItem(this.props.item.uuid, e.target.name, value);
  }

  currencyChange(e, { value, name }) {
    this.props.updateItem(this.props.item.uuid, name, value);
  }

  onItemImageUpload(files) {
    if (files.length > 0)
      this.props.onItemImageUpload(files[0], this.props.item.uuid);
  }

  deleteItem(e) {
    this.props.deleteItem(this.props.item.uuid);
  }

  render() {
    return (
      <>
        <Grid columns={3} stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <label>Upload a close up of the item:</label>
              <FilepondUploader
                maxFiles={1}
                onImageUpload={this.onItemImageUpload}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <Form unstackable>
                <Form.Group>
                  <Form.Field width={16}>
                    <input
                      maxLength="500"
                      name="name"
                      type="text"
                      placeholder="What is the item called?"
                      value={this.props.item.name}
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field width={4}>
                    <Dropdown
                      compact
                      selection
                      value={this.props.item.currency}
                      onChange={this.currencyChange}
                      options={currencyOptions}
                      name="currency"
                    />
                  </Form.Field>
                  <Form.Field width={12}>
                    <input
                      name="cost"
                      type="number"
                      placeholder="How much does this item cost?"
                      value={this.props.item.cost}
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field width={16}>
                    <input
                      maxLength="2048"
                      name="url"
                      type="url"
                      placeholder="Enter a url to help others find this item"
                      value={this.props.item.url}
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
              <Icon name="delete" onClick={this.deleteItem} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

export default NewLookItem;
