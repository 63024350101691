import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import { createFollow, deleteFollow, followExists } from "../util/DbHelper.js";

class FollowButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { follow: false };
    this.follow = this.follow.bind(this);
    this.unfollow = this.unfollow.bind(this);
    this.isFollowing = this.isFollowing.bind(this);
  }

  componentDidMount() {
    this.isFollowing();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) this.isFollowing();
  }

  async isFollowing() {
    if (this.context && this.context.uid) {
      const follow = await followExists(this.context.uid, this.props.id);
      this.setState({ follow: follow });
    }
  }

  async follow() {
    if (this.context && this.context.uid) {
      await createFollow(this.context.uid, this.props.id);
      this.setState({ follow: true });
    }
  }

  async unfollow() {
    if (this.context && this.context.uid) {
      await deleteFollow(this.context.uid, this.props.id);
      this.setState({ follow: false });
    }
  }

  render() {
    return (
      <>
        {!this.state.follow && (
          <>
            <Button as="div" secondary onClick={this.follow}>
              <Icon name="plus"></Icon> Follow
            </Button>
          </>
        )}
        {this.state.follow && (
          <>
            <Button as="div" onClick={this.unfollow}>
              <Icon name="minus"></Icon> Unfollow
            </Button>
          </>
        )}
      </>
    );
  }
}

FollowButton.contextType = UserContext;

export default FollowButton;
