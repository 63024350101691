import React from "react";
import { Icon } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import { createPin, deletePin, pinExists } from "../util/DbHelper.js";

class PinButton extends React.Component {
  constructor(props) {
    super(props);
    this.pin = this.pin.bind(this);
    this.unPin = this.unPin.bind(this);
    //this.subscribeToPin = this.subscribeToPin.bind(this);
    this.isPinned = this.isPinned.bind(this);

    this.state = { pinned: false };
  }

  componentDidMount() {
    this.isPinned();
  }

  async isPinned() {
    if (this.context && this.context.uid) {
      const pinned = await pinExists(this.context.uid, this.props.id);
      this.setState({ pinned: pinned });
    }
  }

  async pin() {
    if (this.context && this.context.uid) {
      await createPin(this.context.uid, this.props.id);
      this.setState({ pinned: true });
    }
  }

  async unPin() {
    if (this.context && this.context.uid) {
      deletePin(this.context.uid, this.props.id);
      this.setState({ pinned: false });
    }
  }

  render() {
    return (
      <>
        {this.state.pinned && (
          <Icon
            rotated="clockwise"
            bordered={true}
            name="pin"
            onClick={this.unPin}
          />
        )}
        {!this.state.pinned && (
          <Icon name="pin" color="grey" onClick={this.pin} />
        )}
      </>
    );
  }
}

PinButton.contextType = UserContext;

export default PinButton;
