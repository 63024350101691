import React from "react";
import CategoryChip from "./CategoryChip.js";

class CategorySelector extends React.Component {
  render() {
    const categoryChips = Object.keys(this.props.categories).map(
      (keyName, i) => (
        <CategoryChip
          key={keyName}
          id={keyName}
          active={this.props.categories[keyName].active}
          category={this.props.categories[keyName].display}
          categoryColour={this.props.categories[keyName].categoryColour}
          categoryChange={this.props.categoryChange}
        />
      )
    );
    return <>{categoryChips}</>;
  }
}

export default CategorySelector;
