import React from "react";
import { Comment, Icon } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import dayjs from "dayjs";

class Reply extends React.Component {
  constructor(props) {
    super(props);
    this.deleteReply = this.deleteReply.bind(this);
  }

  deleteReply() {
    this.props.deleteReply(this.props.id);
  }

  render() {
    return (
      <Comment.Group>
        <Comment>
          <Comment.Avatar src={this.props.image} />
          <Comment.Content>
            <Comment.Author as="a">
              <Icon className="no-margin" name="at" />
              {this.props.username}
            </Comment.Author>
            <Comment.Metadata>
              <div>{dayjs(this.props.createdAt).format("DD/MM/YYYY")}</div>
            </Comment.Metadata>
            <Comment.Text>{this.props.body}</Comment.Text>
            <Comment.Actions>
              <Comment.Action onClick={this.props.toggleReply}>
                Reply
              </Comment.Action>
              {this.context && this.props.userId === this.context.uid && (
                <Comment.Action onClick={this.deleteReply}>
                  Delete
                </Comment.Action>
              )}
            </Comment.Actions>
          </Comment.Content>
        </Comment>
      </Comment.Group>
    );
  }
}

Reply.contextType = UserContext;

export default Reply;
