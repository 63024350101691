import React from "react";
import LikeButton from "./LikeButton.js";
import ImageCarousel from "./ImageCarousel.js";
import Username from "./Username.js";
import PinButton from "./PinButton.js";
import { categories } from "../res/options.js";
import { Card, Label, Grid } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";

class LookCard extends React.Component {
  constructor(props) {
    super(props);
    let lookCategories = [];
    if (props.look.categories) {
      for (const cat of props.look.categories) {
        lookCategories.push({
          category: cat,
          categoryColour: categories[cat].categoryColour,
          display: categories[cat].display,
        });
      }
    }
    const categoryChips = lookCategories.map((cat) => (
      <Label key={cat.category} color={cat.categoryColour}>
        {cat.display}
      </Label>
    ));
    this.state = { categoryChips: categoryChips };
  }

  render() {
    return (
      <Card fluid className="class-custom">
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={12}>
              <div className="username">
                <Username id={this.props.look.user.id} />
              </div>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" verticalAlign="middle">
              <PinButton id={this.props.look.id} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ImageCarousel
          lookId={this.props.look.id}
          allowRedirect={true}
          className="max-height"
          images={this.props.images}
          thumb={false}
        />
        <Card.Content>{this.state.categoryChips}</Card.Content>
        <Card.Content extra>
          <LikeButton
            id={this.props.look.id}
            noLikes={this.props.look.likes}
            noComments={this.props.look.comments}
          />
        </Card.Content>
      </Card>
    );
  }
}

LookCard.contextType = UserContext;

export default LookCard;
