import React from "react";

const CookiePolicy = () => {
  return (
    <>
      <p>
        This Cookie Policy explains how we use cookies and similar technologies
        to collect and store information when you visit our website. By
        continuing to browse our site, you consent to the use of cookies as
        described in this policy.
      </p>

      <h3>What are Cookies?</h3>
      <p>
        Cookies are small text files that are placed on your computer or mobile
        device when you visit a website. They are widely used to make websites
        work or improve the efficiency of a website, as well as to provide
        reporting information and personalized advertising.
      </p>

      <h3>How We Use Cookies</h3>
      <p>We use cookies for various purposes, including:</p>
      <ul>
        <li>Ensuring the proper functioning of our website</li>
        <li>
          Collecting statistical information about website traffic and usage
        </li>
        <li>Personalizing and improving your experience on our website</li>
        <li>Enabling certain features and functionalities</li>
        <li>Providing relevant advertising based on your interests</li>
      </ul>

      <h3>Types of Cookies We Use</h3>
      <p>
        The cookies we use on our website fall into the following categories:
      </p>
      <ul>
        <li>
          <strong>Strictly Necessary Cookies:</strong> These cookies are
          essential for the operation of our website and cannot be disabled.
          They are usually set in response to actions you take, such as setting
          your privacy preferences, logging in, or filling out forms.
        </li>
        <li>
          <strong>Performance and Analytics Cookies:</strong> These cookies
          allow us to collect information about how visitors use our website,
          such as the pages visited, the time spent on the site, and any error
          messages encountered. This helps us improve the performance and
          usability of our website.
        </li>
        <li>
          <strong>Functionality Cookies:</strong> These cookies enable certain
          features and functionalities on our website, such as remembering your
          preferences or personalizing your experience.
        </li>
        <li>
          <strong>Advertising Cookies:</strong> These cookies are used to
          deliver relevant advertisements to you based on your interests. They
          may also be used to limit the number of times you see an advertisement
          and measure the effectiveness of advertising campaigns.
        </li>
      </ul>

      <h3>Firebase Cookies</h3>
      <p>
        In addition to the cookies mentioned above, our website may use cookies
        associated with Firebase services. These cookies are set by Firebase to
        provide functionality such as authentication, analytics, and performance
        optimization. Please refer to Firebase's privacy policy for more
        information on the cookies they use and how they handle your data.
      </p>

      <h3>Consent Cookie</h3>
      <p>
        We store your consent for our cookie and privacy policies in a cookie to
        comply with legal requirements. By accepting our cookie policy and
        privacy policy, you consent to the use of this cookie.
      </p>

      <h3>Managing Cookies</h3>
      <p>
        You can control and manage cookies in various ways. Most web browsers
        allow you to delete cookies, block cookies, or alert you before a cookie
        is stored. Please refer to your browser's help documentation for more
        information on how to manage cookies. Note that disabling or blocking
        certain cookies may affect the functionality of our website.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions or concerns about our use of cookies or this
        Cookie Policy, please contact us.
      </p>
    </>
  );
};

export default CookiePolicy;
