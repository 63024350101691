import React from "react";
import logo from "../res/logo.png";
import LoginMenu from "./LoginMenu.js";
import GlobalSearch from "../util/GlobalSearch.js";
import { Grid, Container, Menu, Segment, Divider } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import { DeviceContext } from "../contexts/DeviceContext";
import { NavLink, Link } from "react-router-dom";

class Header extends React.Component {
  constructor(props, context) {
    super(props);
    let activeItem = "";
    this.state = { activeItem: activeItem };
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(e, { name }) {
    this.setState({ activeItem: name });
  }

  render() {
    const headerLogo = <img alt="" className="header-logo" src={logo} />;

    return (
      <DeviceContext.Consumer>
        {(device) => (
          <Segment>
            <Container>
              <Grid columns={device.type === "mobile" ? 2 : 3}>
                {device.type === "mobile" && (
                  <Grid.Row>
                    <Grid.Column floated="left" verticalAlign="middle">
                      <Link to="/discover">{headerLogo}</Link>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle">
                      <LoginMenu
                        handleItemClick={this.handleItemClick}
                        activeItem={this.state.activeItem}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Divider className="no-margin" />
                {device.type === "mobile" && (
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <GlobalSearch className="full-search-bar" size="mini" />
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row columns={device.type === "mobile" ? 1 : 3}>
                  <Grid.Column
                    className="menu-bar-parent"
                    verticalAlign="bottom"
                  >
                    {(device.type !== "mobile" ||
                      (this.context && this.context.uid)) && (
                      <Menu secondary className="menu-bar">
                        <Menu.Item
                          as={NavLink}
                          to="/discover"
                          name="Discover"
                          active={this.state.activeItem === "Discover"}
                          onClick={this.handleItemClick}
                        />
                        {this.context && this.context.uid && (
                          <>
                            <Menu.Item
                              as={NavLink}
                              to={"/user/" + this.context.uid}
                              name="My Looks"
                              active={this.state.activeItem === "My Looks"}
                              onClick={this.handleItemClick}
                            />
                            <Menu.Item
                              as={NavLink}
                              to="/pins"
                              name="Pins"
                              active={this.state.activeItem === "Pins"}
                              onClick={this.handleItemClick}
                            />
                          </>
                        )}
                      </Menu>
                    )}
                  </Grid.Column>
                  {device.type !== "mobile" && (
                    <>
                      <Grid.Column verticalAlign="bottom">
                        <Link to="/discover">{headerLogo}</Link>
                      </Grid.Column>
                      <Grid.Column verticalAlign="bottom">
                        <Grid columns={2}>
                          <Grid.Row>
                            <Grid.Column verticalAlign="bottom">
                              <GlobalSearch size="small" fluid={false} />
                            </Grid.Column>
                            <Grid.Column verticalAlign="bottom">
                              <LoginMenu
                                handleItemClick={this.handleItemClick}
                                activeItem={this.state.activeItem}
                                userId={this.context ? this.context.uid : null}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    </>
                  )}
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        )}
      </DeviceContext.Consumer>
    );
  }
}

Header.contextType = UserContext;

export default Header;
