import React from "react";
import { Card } from "semantic-ui-react";

class Ad extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <Card fluid className="class-custom">
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-format="fluid"
          data-ad-layout-key="+2t+rl+2h-1m-4u"
          data-ad-client="ca-pub-5917399136327274"
          data-ad-slot="7860935969"
          data-adtest="on"
        ></ins>
      </Card>
    );
  }
}

export default Ad;
