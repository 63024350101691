import React from "react";
import NewLookItemTableGroup from "./NewLookItemTableGroup.js";
import FilepondUploader from "../util/FilepondUploader.js";
import {
  Button,
  Container,
  Segment,
  Dropdown,
  Form,
  Grid,
  Message,
} from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import { categories, genderOptions } from "../res/options.js";
import { UserContext } from "../contexts/UserContext";
import { DeviceContext } from "../contexts/DeviceContext";
import CategorySelector from "../util/CategorySelector.js";
import { withRouter } from "react-router";
import {
  putImage,
  removeImages,
  createLookAndLookItems,
} from "../util/DbHelper.js";

class LookDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newLookId: uuidv4(),
      items: [],
      deletedItems: [],
      caption: "",
      lookImages: [],
      uploadedImages: [],
      genderSelection: "MALE",
      categories: categories,
      error: false,
      saving: false,
      errors: [],
    };
    this.addItem = this.addItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.onItemImageUpload = this.onItemImageUpload.bind(this);
    this.updateCaption = this.updateCaption.bind(this);
    this.onLookImageUpload = this.onLookImageUpload.bind(this);
    this.uploadLook = this.uploadLook.bind(this);
    this.submitUploadLook = this.submitUploadLook.bind(this);
    this.genderChange = this.genderChange.bind(this);
    this.chipChange = this.chipChange.bind(this);
    this.validURL = this.validURL.bind(this);
    this.getLookData = this.getLookData.bind(this);
    this.getLookItemData = this.getLookItemData.bind(this);
  }

  validateLook() {
    let valid = true;
    let errors = [];

    if (this.state.lookImages.length === 0) {
      valid = false;
      errors.push("Look requires images");
    }
    for (const item of this.state.items) {
      if (item.url && (!this.validURL(item.url) || item.url.length > 2048)) {
        valid = false;
        errors.push("Invalid URL");
      }
      // if (!item.s3Image) {
      //   valid = false;
      //   errors.push("Item missing image");
      // }
      if (!item.name || item.name.length > 500) {
        valid = false;
        errors.push("Invalid or missing name");
      }
      // if (!item.url) {
      //   valid = false;
      //   errors.push("Item missing url");
      // }
      if (item.cost > 9999999) {
        valid = false;
        errors.push("Invalid item cost");
      }
    }

    this.setState({ errors: errors });
    return valid;
  }

  async submitUploadLook() {
    this.setState({ error: false, saving: true });
    if (this.validateLook()) await this.uploadLook();
    else this.setState({ error: true });
    this.setState({ saving: false });
  }

  getchips(chipType) {
    let chips = [];
    for (const chip in this.state[chipType]) {
      if (this.state[chipType][chip].active)
        chips.push(chip.toUpperCase().replace(" ", "_"));
    }
    return chips;
  }

  async getLookData() {
    var s3Images = [];
    const categories = this.getchips("categories");

    //Save look images in s3 bucket
    for (const image of this.state.lookImages) {
      const s3LookKey = await putImage(this.context.uid, image);
      s3Images.push(s3LookKey);
      this.setState((prevState) => ({
        uploadedImages: [...prevState.uploadedImages, s3LookKey],
      }));
    }

    const newLook = {
      id: this.state.newLookId,
      caption: this.state.caption,
      images: s3Images,
      gender: this.state.genderSelection,
      categories: categories,
    };

    return newLook;
  }

  async getLookItemData() {
    var lookItems = [];
    //Save look item images in s3 bucket
    for (const item of this.state.items) {
      let s3ItemKey = "";
      if (item.s3Image) {
        s3ItemKey = await putImage(this.context.uid, item.s3Image);
        this.setState((prevState) => ({
          uploadedImages: [...prevState.uploadedImages, s3ItemKey],
        }));
      }

      lookItems.push({
        id: uuidv4(),
        image: s3ItemKey,
        type: item.itemType,
        name: item.name,
        url: item.url,
        cost: item.cost,
        currency: item.currency,
        lookId: this.state.newLookId,
      });
    }

    return lookItems;
  }

  async uploadLook() {
    try {
      const newLook = await this.getLookData();
      const lookItems = await this.getLookItemData();
      await createLookAndLookItems(newLook, lookItems, this.context.uid);
      this.props.history.push("/look/" + this.state.newLookId);
    } catch (err) {
      console.log(err);
      removeImages(this.state.uploadedImages);
      this.setState({
        error: true,
        errors: ["Could not upload look. " + err.message],
      });
    }
  }

  addItem(itemType) {
    const uuid = uuidv4();
    if (this.state.items.length < 24) {
      this.setState((prevState) => ({
        items: prevState.items.concat({
          dbId: "",
          uuid: uuid,
          itemType: itemType,
          s3Image: null,
          name: "",
          url: "",
          cost: "",
          currency: "GBP",
        }),
      }));
    }
  }

  onItemImageUpload(file, uuid) {
    this.setState((prevState) => ({
      items: prevState.items.map((el) =>
        el.uuid === uuid ? { ...el, s3Image: file } : el
      ),
    }));
  }

  onLookImageUpload(pictureFiles) {
    this.setState({ lookImages: pictureFiles });
  }

  updateItem(uuid, name, value) {
    this.setState((prevState) => ({
      items: prevState.items.map((el) =>
        el.uuid === uuid ? { ...el, [name]: value } : el
      ),
    }));
  }

  updateCaption(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    console.log(name);

    if (name === "caption" && value.length > 500)
      value = value.substring(0, 500);

    this.setState({ caption: value });
  }

  deleteItem(uuid) {
    var items = [...this.state.items]; // make a separate copy of the array
    const index = this.state.items.findIndex((item) => item.uuid === uuid);
    if (index !== -1) {
      const deletedItem = items.splice(index, 1);
      this.setState((prevState) => ({
        items: items,
        deletedItems: prevState.deletedItems.concat(deletedItem),
      }));
    }
  }

  genderChange(event, { value }) {
    this.setState({ genderSelection: value });
  }

  chipChange(event, type) {
    const keyName = event.target.id;
    this.setState((prevState) => ({
      [type]: {
        ...prevState[type],
        [keyName]: {
          ...prevState[type][keyName],
          active: !prevState[type][keyName].active,
        },
      },
    }));
  }

  validURL(str) {
    var expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    var regex = new RegExp(expression);
    return str.match(regex);
  }

  render() {
    return (
      <DeviceContext.Consumer>
        {(device) => (
          <Container>
            <Segment>
              <Form>
                <Container text>
                  <Form.Field>
                    <label>Upload images of your look</label>
                  </Form.Field>
                </Container>
                <Grid centered columns={device.type === "mobile" ? 1 : 2}>
                  <Grid.Column>
                    <FilepondUploader
                      maxFiles={5}
                      onImageUpload={this.onLookImageUpload}
                    />
                  </Grid.Column>
                </Grid>
                <Container text>
                  <Form.Field>
                    <label>Choose Categories</label>
                    <CategorySelector
                      categories={this.state.categories}
                      categoryChange={this.chipChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="top-buffer">Gender</label>
                    <Dropdown
                      fluid
                      selection
                      options={genderOptions}
                      onChange={this.genderChange}
                      defaultValue="MALE"
                    />
                  </Form.Field>
                  <Form.Field>
                    <textarea
                      className="top-buffer"
                      name="caption"
                      placeholder="Enter a caption for your look here."
                      value={this.state.caption}
                      onChange={this.updateCaption}
                    />
                    <p className="char-count">
                      {this.state.caption.length}/500
                    </p>
                  </Form.Field>
                </Container>
              </Form>
              <NewLookItemTableGroup
                items={this.state.items}
                addItem={this.addItem}
                updateItem={this.updateItem}
                deleteItem={this.deleteItem}
                onItemImageUpload={this.onItemImageUpload}
              />
              {this.state.error && (
                <Message error>
                  <Message.Header>Error</Message.Header>
                  <Message.List>
                    {this.state.errors.map((rule, index) => (
                      <Message.Item key={index}>{rule}</Message.Item>
                    ))}
                  </Message.List>
                </Message>
              )}
              <Button
                secondary
                disabled={this.state.saving}
                loading={this.state.saving}
                onClick={this.submitUploadLook}
                type="submit"
              >
                Save
              </Button>
            </Segment>
          </Container>
        )}
      </DeviceContext.Consumer>
    );
  }
}

LookDetails.contextType = UserContext;

export default withRouter(LookDetails);
