import React from "react";
import {
  Comment as SemanticComment,
  Form,
  Button,
  Ref,
  Icon,
} from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import dayjs from "dayjs";
import Reply from "./Reply.js";
import { createComment, getComments, deleteComment } from "../util/DbHelper.js";
import * as Sentry from "@sentry/react";

class Comment extends React.Component {
  constructor(props, context) {
    super(props);
    this.textInput = React.createRef();

    this.deleteComment = this.deleteComment.bind(this);
    this.deleteReply = this.deleteReply.bind(this);
    this.deleteSelf = this.deleteSelf.bind(this);
    this.createReply = this.createReply.bind(this);
    this.getComments = this.getComments.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleReply = this.toggleReply.bind(this);
    this.replyInput = this.focusTextInput.bind(this);
    this.state = {
      replyBody: "",
      reply: false,
      hasMore: false,
      replies: [],
      addLoading: false,
      loadingMore: false,
    };
  }

  componentDidMount() {
    this.getComments();
  }

  focusTextInput() {
    this.textInput.current.firstChild.focus();
  }

  async createReply() {
    this.setState({ addLoading: true });
    if (this.context && this.context.uid) {
      try {
        const reply = await createComment(
          this.context.uid,
          this.props.lookId,
          this.state.replyBody,
          this.props.id,
          1
        );
        this.setState((prevState) => ({
          replies: [...prevState.replies, reply],
        }));
      } catch (err) {
        Sentry.captureException(err);
      }
    }
    this.setState({ addLoading: false });
  }

  async getComments() {
    this.setState({ loadingMore: true });
    const queryResult = await getComments(
      this.props.lookId,
      this.state.lastVisible,
      this.props.id
    );

    this.setState((prevState) => ({
      replies: [...prevState.replies, ...queryResult.comments],
      lastVisible: queryResult.lastVisible,
      hasMore: queryResult.hasMore,
    }));
    this.setState({ loadingMore: false });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  deleteComment(id, remove) {
    this.props.deleteComment(id, remove);
  }

  deleteSelf() {
    this.deleteComment(this.props.id, true);
  }

  deleteReply(id) {
    deleteComment(id, false);
    var items = [...this.state.replies]; // make a separate copy of the array
    const index = this.state.replies.findIndex((item) => item.id === id);
    if (index !== -1) {
      items.splice(index, 1);
      this.setState((prevState) => ({ replies: items }));
    }
  }

  async toggleReply() {
    await this.setState((prevState) => ({ reply: !prevState.reply }));
    if (this.state.reply) this.focusTextInput();
  }

  render() {
    return (
      <SemanticComment>
        <SemanticComment.Avatar src={this.props.image} />
        <SemanticComment.Content>
          <SemanticComment.Author as="a">
            <Icon className="no-margin" name="at" />
            {this.props.username}
          </SemanticComment.Author>
          <SemanticComment.Metadata>
            <div>{dayjs(this.props.createdAt).format("DD/MM/YYYY")}</div>
          </SemanticComment.Metadata>
          <SemanticComment.Text>{this.props.body}</SemanticComment.Text>
          <SemanticComment.Actions>
            {this.context && (
              <SemanticComment.Action onClick={this.toggleReply}>
                Reply
              </SemanticComment.Action>
            )}
            {this.context && this.props.userId === this.context.uid && (
              <SemanticComment.Action onClick={this.deleteSelf}>
                Delete
              </SemanticComment.Action>
            )}
          </SemanticComment.Actions>
          {this.state.replies.map((reply) => (
            <Reply
              key={reply.id}
              id={reply.id}
              lookId={this.props.lookId}
              userId={reply.user.id}
              username={reply.user.username}
              image={reply.user.signedImage}
              createdAt={reply.createdAt}
              body={reply.body}
              deleteReply={this.deleteReply}
              toggleReply={this.toggleReply}
            />
          ))}
          {this.state.hasMore && (
            <Button
              disabled={this.state.loadingMore}
              loading={this.state.loadingMore}
              onClick={this.getComments}
              content="Load More"
            />
          )}
          {this.context && this.state.reply && (
            <Form reply>
              <Ref innerRef={this.textInput}>
                <Form.TextArea
                  name="replyBody"
                  value={this.state.replyBody}
                  onChange={this.handleInputChange}
                />
              </Ref>
              <Button
                disabled={this.state.addLoading}
                loading={this.state.addLoading}
                onClick={this.createReply}
                content="Add Reply"
                labelPosition="left"
                icon="edit"
                primary
              />
            </Form>
          )}
        </SemanticComment.Content>
      </SemanticComment>
    );
  }
}

Comment.contextType = UserContext;

export default Comment;
