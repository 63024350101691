import React from "react";
import LookItem from "./LookItem.js";
import { Header, Divider, Loader } from "semantic-ui-react";
import { getLookItems } from "../util/DbHelper.js";

class LookItemTable extends React.Component {
  constructor(props, context) {
    super(props);
    this.getLookItems = this.getLookItems.bind(this);
    this.state = { items: [], loading: false };
  }

  componentDidMount() {
    this.getLookItems();
  }

  async getLookItems() {
    this.setState({ loading: true });
    let items = await getLookItems(this.props.lookId, this.props.type);
    if (items.length === 0) {
      this.props.hideGroup(this.props.type);
    } else {
      this.setState({
        items: items,
      });
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <>
        <Divider horizontal>
          <Header as="h4">{this.props.type}</Header>
        </Divider>
        {this.state.loading && (
          <Loader active inline="centered" size="massive" />
        )}
        {this.state.items.map((lookItem, index) => (
          <LookItem
            key={lookItem.id}
            image={lookItem.signedImage}
            url={lookItem.url}
            cost={lookItem.cost}
            name={lookItem.name}
            currency={lookItem.currency}
          />
        ))}
      </>
    );
  }
}

export default LookItemTable;
