import React from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {
  Container,
  Grid,
  Segment,
  Form,
  Message,
  Button,
} from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { DeviceContext } from "../contexts/DeviceContext";
import { withRouter } from "react-router";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      verificationCode: "",
      successMessage: "",
      errorMessage: "",
      resetSent: false,
      formError: false,
      loading: false,
    };
    this.sendReset = this.sendReset.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  sendReset() {
    this.setState({ loading: true });
    var actionCodeSettings = {
      url: 'https://thatgetup.com/login',
    };
    const auth = getAuth();
    sendPasswordResetEmail(auth, this.state.email, actionCodeSettings)
      .then(() => {
        this.setState({
          resetSent: true,
          formError: false,
          successMessage: "Password reset sent",
        });
      })
      .catch((error) => {
        this.setState({
          resetSent: false,
          formError: true,
          errorMessage: error.message,
        });
      }).finally(() => {
        this.setState({ loading: false });
      })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <DeviceContext.Consumer>
        {(device) => (
          <>
            {!this.context && (
              <Container>
                <Grid>
                  <Grid.Column
                    width={device.type === "mobile" ? 1 : 5}
                  ></Grid.Column>
                  <Grid.Column width={device.type === "mobile" ? 14 : 6}>
                    <Segment>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            <Form
                              success={this.state.resetSent}
                              error={this.state.formError}
                            >
                              <Message
                                success
                                header="Code Sent"
                                content={this.state.successMessage}
                              />
                              <Message
                                error
                                header="Error"
                                content={this.state.errorMessage}
                              />
                              <Form.Field>
                                <label>Email</label>
                                <input
                                  type="email"
                                  onChange={this.handleInputChange}
                                  value={this.state.email}
                                  placeholder="Email"
                                  name="email"
                                />
                              </Form.Field>
                            </Form>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Button
                              onClick={this.sendReset}
                              onKeyPress={this.sendReset}
                              loading={this.state.loading}
                              disabled={this.state.loading}
                            >
                              {this.state.resetSent
                                ? "Resend Password Reset"
                                : "Send Password Reset"}
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column
                    width={device.type === "mobile" ? 1 : 5}
                  ></Grid.Column>
                </Grid>
              </Container>
            )}
            {this.context && <Redirect to="/feed" />}
          </>
        )}
      </DeviceContext.Consumer>
    );
  }
}

ForgotPassword.contextType = UserContext;

export default withRouter(ForgotPassword);
