import React from "react";
import InfiniteFeed from "../util/InfiniteFeed.js";
import { searchLooks } from "../util/DbHelper.js";
import FilterMenu from "./FilterMenu.js";
import { categories } from "../res/options.js";
import { UserContext } from "../contexts/UserContext";
import { DeviceContext } from "../contexts/DeviceContext";
import {
  Grid,
  Ref,
  Sticky,
  Rail,
  Button,
  Modal,
  Loader,
} from "semantic-ui-react";
import * as Sentry from "@sentry/react";

class Discover extends React.Component {
  constructor(props, context) {
    super(props);
    let genderSelection = "ALL";
    if (context && context.profile && context.profile.gender) {
      genderSelection = context.profile.gender;
      console.log(genderSelection);
    }

    if (context) {
      //genderSelection = context.attributes.gender;
    }
    let sortSelection = "RECENT";
    this.contextRef = React.createRef();
    this.categoryChange = this.categoryChange.bind(this);
    this.sortChange = this.sortChange.bind(this);
    this.genderChange = this.genderChange.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.loadLooks = this.loadLooks.bind(this);
    this.state = {
      loading: false,
      showFilter: false,
      search: "",
      categories: categories,
      sortSelection: sortSelection,
      genderSelection: genderSelection,
      error: false,
      hasMore: true,
      lastVisible: null,
      looks: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.loadLooks();
  }

  categoryChange(event) {
    const keyName = event.target.id;
    this.setState(
      (prevState) => ({
        categories: {
          ...prevState.categories,
          [keyName]: {
            ...prevState.categories[keyName],
            active: !prevState.categories[keyName].active,
          },
        },
      }),
      this.updateQuery
    );
  }

  updateQuery() {
    this.setState(
      {
        loading: true,
        error: false,
        hasMore: true,
        lastVisible: null,
        looks: [],
      },
      this.loadLooks
    );
  }

  sortChange(event, { value }) {
    this.setState({ sortSelection: value }, this.updateQuery);
  }

  genderChange(event, { value }) {
    this.setState({ genderSelection: value }, this.updateQuery);
  }

  toggleFilter() {
    this.setState((prevState) => ({ showFilter: !prevState.showFilter }));
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async loadLooks() {
    let selectedCategories = [];
    for (const [key, value] of Object.entries(this.state.categories)) {
      if (value.active) selectedCategories.push(key);
    }
    try {
      const result = await searchLooks(
        this.state.lastVisible,
        this.state.genderSelection,
        selectedCategories,
        this.state.sortSelection
      );

      this.setState((prevState) => ({
        looks: [...prevState.looks, ...result.looks],
        lastVisible: result.lastVisible,
        hasMore: result.hasMore,
      }));
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      this.setState({ error: true });
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <DeviceContext.Consumer>
        {(device) => (
          <>
            {device.type !== "wide screen" && (
              <Modal
                onClose={this.toggleFilter}
                onOpen={this.toggleFilter}
                open={this.state.showFilter}
                trigger={<Button fluid>Filter</Button>}
              >
                <FilterMenu
                  search={this.state.search}
                  searchChange={this.searchChange}
                  handleInputChange={this.handleInputChange}
                  categories={this.state.categories}
                  categoryChange={this.categoryChange}
                  gender={this.state.genderSelection}
                  genderChange={this.genderChange}
                  sortChange={this.sortChange}
                  sort={this.state.sortSelection}
                />
                <Modal.Actions>
                  <Button color="black" onClick={this.toggleFilter}>
                    Close
                  </Button>
                </Modal.Actions>
              </Modal>
            )}
            <Grid
              className="no-margin-x"
              centered
              columns={device.type === "wide screen" ? 2 : 1}
            >
              <Grid.Column className="no-padding-x">
                <Ref innerRef={this.contextRef}>
                  <div>
                    {device.type === "wide screen" && (
                      <Rail className="small-margin-top" position="left">
                        <Sticky context={this.contextRef} offset={5}>
                          <FilterMenu
                            search={this.state.search}
                            searchChange={this.searchChange}
                            handleInputChange={this.handleInputChange}
                            categories={this.state.categories}
                            categoryChange={this.categoryChange}
                            gender={this.state.genderSelection}
                            genderChange={this.genderChange}
                            sortChange={this.sortChange}
                          />
                        </Sticky>
                      </Rail>
                    )}
                    {this.state.loading && (
                      <Loader active inline="centered" size="massive" />
                    )}
                    {!this.state.loading && (
                      <InfiniteFeed
                        loadLooks={this.loadLooks}
                        looks={this.state.looks}
                        hasMore={this.state.hasMore}
                        error={this.state.error}
                        noColumns={device.type === "mobile" ? 1 : 2}
                      />
                    )}
                  </div>
                </Ref>
              </Grid.Column>
            </Grid>
          </>
        )}
      </DeviceContext.Consumer>
    );
  }
}

Discover.contextType = UserContext;

export default Discover;
