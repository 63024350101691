import { v4 as uuidv4 } from "uuid";
import { Counter } from "../sharded-counter.js";
import {
  getFirestore,
  writeBatch,
  doc,
  getDoc,
  serverTimestamp,
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getDocs,
  setDoc,
  deleteDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const num_shards = 5;

export async function createUser(uid, username, firstName, lastName, gender) {
  const db = getFirestore();

  let batch = writeBatch(db);
  batch.set(doc(db, "User", uid), {
    firstName: firstName,
    lastName: lastName,
    username: username,
    gender: gender,
    following: 0,
    followers: 0,
  });
  batch.set(doc(db, "Index/User/username", username), {
    value: uid,
  });
  await batch.commit();
}

export async function usernameAvailable(username) {
  const db = getFirestore();
  const docRef = doc(db, "Index/User/username", username);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return false;
  } else {
    return true;
  }
}

export async function userExists(uid) {
  const db = getFirestore();
  const docRef = doc(db, "User", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return true;
  } else {
    return false;
  }
}

export async function searchUsername(queryText, last) {
  const db = getFirestore();
  const queryLimit = 10;
  let queryResult = null;

  if (!last) {
    // Query the first page of docs
    queryResult = query(
      collection(db, "User"),
      where("username", ">=", queryText),
      where("username", "<=", queryText + "\uf8ff"),
      limit(queryLimit)
    );
  } else {
    queryResult = query(
      collection(db, "User"),
      where("username", ">=", queryText),
      where("username", "<=", queryText + "\uf8ff"),
      startAfter(last),
      limit(queryLimit)
    );
  }

  const documentSnapshots = await getDocs(queryResult);
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  let users = [];

  for (const snapshot of documentSnapshots.docs) {
    let userData = snapshot.data();
    const signedImage = await retrieveImage(userData.image);
    userData.id = snapshot.id;
    userData.signedImage = signedImage;
    users.push(userData);
  }

  return {
    users: users,
    lastVisible,
    hasMore: documentSnapshots.docs.length === queryLimit,
  };
}

export async function getUser(uid) {
  const db = getFirestore();
  const docRef = doc(db, "User", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    let user = docSnap.data();
    if (user.image) {
      user.id = docSnap.id;
      user.signedImage = await retrieveImage(user.image);
    }
    return user;
  } else {
    return null;
  }
}

export async function getUserFromReference(userRef) {
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    let user = docSnap.data();
    user.id = docSnap.id;
    if (user.image) {
      user.signedImage = await retrieveImage(user.image);
    }
    return user;
  } else {
    return null;
  }
}

export async function createLookAndLookItems(look, lookItems, userId) {
  const db = getFirestore();
  const userRef = doc(db, `User/${userId}`);

  let batch = writeBatch(db);
  batch.set(doc(db, "Look", look.id), {
    caption: look.caption,
    images: look.images,
    user: userRef,
    gender: look.gender,
    categories: look.categories,
    likes: 0,
    comments: 0,
    createdAt: serverTimestamp(),
  });
  for (const item of lookItems) {
    batch.set(doc(db, `Look/${look.id}/LookItem`, item.id), {
      image: item.image,
      type: item.type,
      name: item.name,
      url: item.url,
      cost: item.cost,
      currency: item.currency,
      lookId: item.lookId,
      createdAt: serverTimestamp(),
    });
  }

  await batch.commit();
  incrementCounter(db, `User/${userId}`, "looks", 1);
}

export async function removeImages(images) {
  const storage = getStorage();

  for (const image of images) {
    // Create a reference to the file to delete
    const desertRef = ref(storage, image);
    // Delete the file
    await deleteObject(desertRef);
  }
}

export async function getLooksByUser(userId, last) {
  const db = getFirestore();
  const queryLimit = 5;
  let queryResult = null;
  const userRef = doc(db, `User/${userId}`);

  if (!last) {
    // Query the first page of docs
    queryResult = query(
      collection(db, "Look"),
      where("user", "==", userRef),
      orderBy("createdAt", "desc"),
      limit(queryLimit)
    );
  } else {
    queryResult = query(
      collection(db, "Look"),
      where("user", "==", userRef),
      orderBy("createdAt", "desc"),
      startAfter(last),
      limit(queryLimit)
    );
  }

  const documentSnapshots = await getDocs(queryResult);
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  return {
    looks: await getLooksFromSnapshots(db, documentSnapshots),
    lastVisible,
    hasMore: documentSnapshots.docs.length === queryLimit,
  };
}

export async function searchLooks(last, gender, categories, search) {
  const db = getFirestore();
  const queryLimit = 5;
  let queryResult = null;
  const searchField = search === "RECENT" ? "createdAt" : "likes";

  if (!last) {
    // Query the first page of docs
    if (categories.length === 0) {
      if (gender === "ALL") {
        queryResult = query(
          collection(db, "Look"),
          orderBy(searchField, "desc"),
          limit(queryLimit)
        );
      } else {
        queryResult = query(
          collection(db, "Look"),
          where("gender", "==", gender),
          orderBy(searchField, "desc"),
          limit(queryLimit)
        );
      }
    } else {
      if (gender === "ALL") {
        queryResult = query(
          collection(db, "Look"),
          where("categories", "array-contains-any", categories),
          orderBy(searchField, "desc"),
          limit(queryLimit)
        );
      } else {
        queryResult = query(
          collection(db, "Look"),
          where("gender", "==", gender),
          where("categories", "array-contains-any", categories),
          orderBy(searchField, "desc"),
          limit(queryLimit)
        );
      }
    }
  } else {
    if (categories.length === 0) {
      if (gender === "ALL") {
        queryResult = query(
          collection(db, "Look"),
          orderBy("createdAt", "desc"),
          startAfter(last),
          limit(queryLimit)
        );
      } else {
        queryResult = query(
          collection(db, "Look"),
          where("gender", "==", gender),
          orderBy("createdAt", "desc"),
          startAfter(last),
          limit(queryLimit)
        );
      }
    } else {
      if (gender === "ALL") {
        queryResult = query(
          collection(db, "Look"),
          orderBy("createdAt", "desc"),
          startAfter(last),
          limit(queryLimit)
        );
      } else {
        queryResult = query(
          collection(db, "Look"),
          where("gender", "==", gender),
          orderBy("createdAt", "desc"),
          startAfter(last),
          limit(queryLimit)
        );
      }
    }
  }

  const documentSnapshots = await getDocs(queryResult);
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  return {
    looks: await getLooksFromSnapshots(db, documentSnapshots),
    lastVisible,
    hasMore: documentSnapshots.docs.length === queryLimit,
  };
}

export async function getPinnedLooks(userId, last) {
  const db = getFirestore();
  const queryLimit = 5;
  let queryResult = null;
  const userRef = doc(db, `User/${userId}`);

  if (!last) {
    // Query the first page of docs
    queryResult = query(
      collection(db, `Pin`),
      where("user", "==", userRef),
      limit(queryLimit)
    );
  } else {
    queryResult = query(
      collection(db, `Pin`),
      where("user", "==", userRef),
      startAfter(last),
      limit(queryLimit)
    );
  }

  const documentSnapshots = await getDocs(queryResult);
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  return {
    looks: await getLooksFromReferenceSnapshot(documentSnapshots),
    lastVisible,
    hasMore: documentSnapshots.docs.length === queryLimit,
  };
}

async function getLooksFromReferenceSnapshot(documentSnapshots) {
  let looks = [];

  for (const snapshot of documentSnapshots.docs) {
    const collectionData = snapshot.data();
    const lookDoc = await getDoc(collectionData.look);
    if (lookDoc.exists()) {
      let lookData = lookDoc.data();
      const user = await getDoc(lookData.user);
      const userData = user.data();
      const signedImages = await retrieveImages(lookData.images);
      lookData.user = userData;
      lookData.user.id = user.id;
      lookData.id = lookDoc.id;
      lookData.signedImages = signedImages;
      looks.push(lookData);
    }
  }

  return looks;
}

async function getLooksFromSnapshots(db, documentSnapshots) {
  let looks = [];

  for (const snapshot of documentSnapshots.docs) {
    let lookData = snapshot.data();
    const user = await getDoc(lookData.user);
    const userData = user.data();
    const signedImages = await retrieveImages(lookData.images);
    lookData.user = userData;
    lookData.user.id = user.id;
    lookData.id = snapshot.id;
    lookData.signedImages = signedImages;
    looks.push(lookData);
  }

  return looks;
}

async function getItemsFromSnapshots(documentSnapshots) {
  let items = [];

  for (const snapshot of documentSnapshots.docs) {
    let data = snapshot.data();
    data.id = snapshot.id;
    if (data.user) data.user = await getUserFromReference(data.user);
    items.push(data);
  }

  return items;
}

export async function updateProfilePicture(userId, image) {
  const key = await putImage(userId, image);
  const db = getFirestore();
  const userRef = doc(db, "User", userId);

  await updateDoc(userRef, {
    image: key,
  });
}

export async function updateUserBio(userId, bio) {
  const db = getFirestore();
  const userRef = doc(db, "User", userId);

  await updateDoc(userRef, {
    bio: bio,
  });
}

export async function updateUserFullName(userId, firstName, lastName) {
  const db = getFirestore();
  const userRef = doc(db, "User", userId);

  await updateDoc(userRef, {
    firstName: firstName,
    lastName: lastName,
  });
}

export async function updateUserPreferences(userId, gender) {
  const db = getFirestore();
  const userRef = doc(db, "User", userId);

  await updateDoc(userRef, {
    gender: gender,
  });
}

export async function putImage(userId, image) {
  if (image) {
    const storage = getStorage();
    const { name, type: mimeType } = image;
    const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(name);
    const uuid = uuidv4();
    const key = `images/${userId}/${uuid}${extension && "."}${extension}`;

    // Create a reference to 'images/mountains.jpg'
    const imageRef = ref(storage, key);
    const imageSnapshot = await uploadBytes(imageRef, image);
    return imageSnapshot.ref.fullPath;
  }
  throw new Error("No image file.");
}

async function retrieveImages(images) {
  const storage = getStorage();
  var imageAddresses = [];
  for (var i = 0; i < images.length; i++) {
    try {
      const pathReference = ref(storage, images[i]);
      const url = await getDownloadURL(pathReference);
      imageAddresses.push(url);
    } catch (e) {
      imageAddresses.push("");
    }
  }
  return imageAddresses;
}

async function retrieveImage(image) {
  const storage = getStorage();
  try {
    const pathReference = ref(storage, image);
    const url = await getDownloadURL(pathReference);
    return url;
  } catch {
    return "";
  }
}

export async function createFollow(userId, userToFollowId) {
  const db = getFirestore();
  await setDoc(doc(db, `Follow`, `${userId}#${userToFollowId}`), {
    user: doc(db, `User/${userId}`),
    followedUser: doc(db, `User/${userToFollowId}`),
  });
  incrementCounter(db, `User/${userToFollowId}`, "followers", 1);
  incrementCounter(db, `User/${userId}`, "following", 1);
}

export async function deleteFollow(userId, userToUnfollowId) {
  const db = getFirestore();
  await deleteDoc(doc(db, `Follow`, `${userId}#${userToUnfollowId}`));
  incrementCounter(db, `User/${userToUnfollowId}`, "followers", -1);
  incrementCounter(db, `User/${userId}`, "following", -1);
}

export async function followExists(userId, userIsFollowedId) {
  const db = getFirestore();
  const docRef = doc(db, `Follow`, `${userId}#${userIsFollowedId}`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return true;
  } else {
    return false;
  }
}

export async function getFollowers(userId, last) {
  const db = getFirestore();
  const queryLimit = 5;
  let queryResult = null;
  const userRef = doc(db, `User/${userId}`);

  if (!last) {
    // Query the first page of docs
    queryResult = query(
      collection(db, `Follow`),
      where("followedUser", "==", userRef),
      limit(queryLimit)
    );
  } else {
    queryResult = query(
      collection(db, `Follow`),
      where("followedUser", "==", userRef),
      startAfter(last),
      limit(queryLimit)
    );
  }

  const documentSnapshots = await getDocs(queryResult);
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  let users = [];

  for (const snapshot of documentSnapshots.docs) {
    let collectionData = snapshot.data();
    let userDoc = await getDoc(collectionData.user);
    if (userDoc.exists()) {
      let userData = userDoc.data();
      const signedImage = await retrieveImage(userData.image);
      userData.id = userDoc.id;
      userData.signedImage = signedImage;
      users.push(userData);
    }
  }

  return {
    users: users,
    lastVisible,
    hasMore: documentSnapshots.docs.length === queryLimit,
  };
}

export async function createLike(userId, lookId) {
  const db = getFirestore();
  await setDoc(doc(db, `Like`, `${userId}#${lookId}`), {
    user: doc(db, `User/${userId}`),
    look: doc(db, `Look/${lookId}`),
  });
  incrementCounter(db, `Look/${lookId}`, "likes", 1);
}

export async function deleteLike(userId, lookId) {
  const db = getFirestore();
  await deleteDoc(doc(db, `Like`, `${userId}#${lookId}`));
  incrementCounter(db, `Look/${lookId}`, "likes", -1);
}

export async function likeExists(userId, lookId) {
  const db = getFirestore();
  const docRef = doc(db, `Like`, `${userId}#${lookId}`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return true;
  } else {
    return false;
  }
}

export async function createPin(userId, lookId) {
  const db = getFirestore();
  await setDoc(doc(db, `Pin`, `${userId}#${lookId}`), {
    user: doc(db, `User/${userId}`),
    look: doc(db, `Look/${lookId}`),
  });
}

export async function deletePin(userId, lookId) {
  const db = getFirestore();
  await deleteDoc(doc(db, `Pin`, `${userId}#${lookId}`));
}

export async function pinExists(userId, lookId) {
  const db = getFirestore();
  const docRef = doc(db, `Pin`, `${userId}#${lookId}`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return true;
  } else {
    return false;
  }
}

export async function createComment(
  userId,
  lookId,
  body,
  parentCommentId,
  commentDepth
) {
  const db = getFirestore();
  const uuid = uuidv4();
  const parentCommentRef = parentCommentId
    ? doc(db, `Comment`, parentCommentId)
    : null;

  let fields = {
    look: doc(db, `Look/${lookId}`),
    user: doc(db, `User/${userId}`),
    noLikes: 0,
    commentDepth: commentDepth,
    body: body,
    parentComment: parentCommentRef,
  };
  await setDoc(doc(db, `Comment`, uuid), fields);
  incrementCounter(db, `Look/${lookId}`, "comments", 1);
  return await getComment(uuid);
}

export async function getComment(commentId) {
  const db = getFirestore();
  const docRef = doc(db, "Comment", commentId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    let comment = docSnap.data();
    comment.id = docSnap.id;
    if (comment.user) comment.user = await getUserFromReference(comment.user);
    return comment;
  } else {
    return null;
  }
}

export async function getComments(lookId, last, parentCommentId) {
  const db = getFirestore();
  const queryLimit = 5;
  const lookRef = doc(db, `Look`, lookId);
  const parentCommentRef = parentCommentId
    ? doc(db, `Comment`, parentCommentId)
    : null;
  let queryResult = null;

  if (!last) {
    // Query the first page of docs
    queryResult = query(
      collection(db, `Comment`),
      where("look", "==", lookRef),
      where("parentComment", "==", parentCommentRef),
      limit(queryLimit)
    );
  } else {
    queryResult = query(
      collection(db, `Comment`),
      where("look", "==", lookRef),
      where("parentComment", "==", parentCommentRef),
      startAfter(last),
      limit(queryLimit)
    );
  }

  const documentSnapshots = await getDocs(queryResult);
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  return {
    comments: await getItemsFromSnapshots(documentSnapshots),
    lastVisible,
    hasMore: documentSnapshots.docs.length === queryLimit,
  };
}

export async function deleteComment(commentId, lookId) {
  const db = getFirestore();
  await deleteDoc(doc(db, "Comment", commentId));
  incrementCounter(db, `Look/${lookId}`, "comments", -1);
}

export async function deleteLook(lookId, images, userId) {
  const db = getFirestore();
  const lookItems = await getAllLookItems(lookId);
  removeImages(images);
  for (const lookItem of lookItems) {
    removeImages([lookItem.image]);
  }
  await deleteDoc(doc(db, "Look", lookId));
  incrementCounter(db, `User/${userId}`, "looks", -1);
}

export async function getLook(lookId) {
  const db = getFirestore();
  const docRef = doc(db, "Look", lookId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    let look = docSnap.data();
    if (look.images) {
      look.id = docSnap.id;
      look.signedImages = await retrieveImages(look.images);
    }
    return look;
  } else {
    return null;
  }
}

export async function getAllLookItems(lookId) {
  const db = getFirestore();
  const result = query(collection(db, `Look/${lookId}/LookItem`));
  const documentSnapshots = await getDocs(result);

  let lookItems = [];
  for (const snap of documentSnapshots.docs) {
    let lookItem = snap.data();
    lookItem.id = snap.id;
    lookItems.push(lookItem);
  }

  return lookItems;
}

export async function getLookItems(lookId, type) {
  const db = getFirestore();
  const result = query(
    collection(db, `Look/${lookId}/LookItem`),
    where("type", "==", type)
  );
  const documentSnapshots = await getDocs(result);

  let lookItems = [];
  for (const snap of documentSnapshots.docs) {
    let lookItem = snap.data();
    lookItem.id = snap.id;
    if (lookItem.image) {
      lookItem.signedImage = await retrieveImage(lookItem.image);
    }
    lookItems.push(lookItem);
  }

  return lookItems;
}

async function incrementCounter(db, ref, field, incrementAmount) {
  var count = new Counter(db, doc(db, ref), field);
  return count.incrementBy(incrementAmount);
}
