import React from "react";
import Username from "../util/Username.js";
import LikeButton from "../util/LikeButton.js";
import PinButton from "../util/PinButton.js";
import DeleteLookButton from "../util/DeleteLookButton.js";
import ImageCarousel from "../util/ImageCarousel.js";
import { DeviceContext } from "../contexts/DeviceContext";
import { Container, Segment, Grid } from "semantic-ui-react";

class LookHeader extends React.Component {
  render() {
    return (
      <>
        <DeviceContext.Consumer>
          {(device) => (
            <Grid centered columns={device.type === "wide screen" ? 2 : 1}>
              <Grid.Row>
                <Grid.Column>
                  <Grid.Row>
                    <Segment>
                      <Grid columns={2}>
                        <Grid.Row>
                          <Grid.Column>
                            <div className="username">
                              <Username id={this.props.look.user.id} />
                            </div>
                          </Grid.Column>
                          {this.props.ownPage && (
                            <Grid.Column
                              textAlign="right"
                              verticalAlign="middle"
                            >
                              <DeleteLookButton
                                look={this.props.look}
                                userId={this.props.look.user.id}
                              />
                            </Grid.Column>
                          )}
                          {!this.props.ownPage && (
                            <Grid.Column
                              textAlign="right"
                              verticalAlign="middle"
                            >
                              <PinButton id={this.props.look.id} />
                            </Grid.Column>
                          )}
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Row>
                  <Segment className="no-margin no-padding">
                    <ImageCarousel
                      images={this.props.look.signedImages}
                      thumb={false}
                    />
                  </Segment>
                  <Grid.Row>
                    <Segment>
                      <LikeButton
                        id={this.props.look.id}
                        noLikes={this.props.look.likes}
                        noComments={this.props.look.comments}
                      />
                      <Container
                        className="top-buffer bottom-buffer"
                        text
                        fluid
                      >
                        {this.props.look.caption}
                      </Container>
                    </Segment>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </DeviceContext.Consumer>
      </>
    );
  }
}

export default LookHeader;
