import React from "react";
import { Grid, Image, Container } from "semantic-ui-react";
import { currencyMapping } from "../res/options.js";

class LookItem extends React.Component {
  constructor(props, context) {
    super(props);
  }

  render() {
    return (
      <>
        <Grid columns={2}>
          <Grid.Row>
            {this.props.image && (
              <Grid.Column verticalAlign="middle" width={5}>
                <Image src={this.props.image} size="medium" />
              </Grid.Column>
            )}
            <Grid.Column verticalAlign="middle">
              <Grid.Row>
                <Grid.Column>
                  <Container>
                    <a
                      target="_blank"
                      href={
                        this.props.url.match(/https?:\/\//)
                          ? this.props.url
                          : "https://" + this.props.url
                      }
                    >
                      {this.props.name}
                    </a>
                  </Container>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Container>
                    {this.props.currency
                      ? currencyMapping[this.props.currency]
                      : ""}
                    {this.props.cost}
                  </Container>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

export default LookItem;
