import React from "react";
import LookHeader from "./LookHeader.js";
import LookItemTableGroup from "./LookItemTableGroup.js";
import CommentGroup from "./CommentGroup.js";
import CopyLinkButton from "./CopyLinkButton.js";
import { Container, Segment, Loader } from "semantic-ui-react";
import { withRouter } from "react-router";
import { UserContext } from "../contexts/UserContext";
import { getLook } from "../util/DbHelper.js";

class Look extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      foundLook: null,
      ownPage: false,
      loading: false,
    };
    this.loadLook = this.loadLook.bind(this);
  }

  componentDidMount() {
    this.loadLook();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.loadLook();
    }
  }

  async loadLook() {
    this.setState({
      loading: true,
    });
    const look = await getLook(this.props.match.params.id);
    if (look) {
      this.setState({
        look: look,
        foundLook: true,
        loading: false,
        ownPage:
          this.context && this.context.uid && look.user.id === this.context.uid,
      });
    } else {
      this.setState({
        foundLook: false,
        loading: false,
      });
    }
  }

  render() {
    return (
      <Container>
        <Segment>
          {this.state.loading && (
            <Loader active inline="centered" size="massive" />
          )}
          {this.state.foundLook !== null && !this.state.foundLook && (
            <p>Cannot find look.</p>
          )}
          {this.state.foundLook && (
            <>
              <LookHeader ownPage={this.state.ownPage} look={this.state.look} />
              <CopyLinkButton />
              <LookItemTableGroup lookId={this.state.look.id} />
              <CommentGroup lookId={this.state.look.id} />
            </>
          )}
        </Segment>
      </Container>
    );
  }
}

Look.contextType = UserContext;

export default withRouter(Look);
