import React from "react";
import { Popup, Button, Image, Icon } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/react";

class RelatedUserSearch extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = { users: [], lastVisible: null };
    this.loadUsers = this.loadUsers.bind(this);
  }

  async loadUsers() {
    try {
      const result = await this.props.query(
        this.props.userId,
        this.state.lastVisible
      );

      this.setState((prevState) => ({
        users: [...prevState.users, ...result.users],
        lastVisible: result.lastVisible,
        hasMore: result.hasMore,
      }));
    } catch (err) {
      Sentry.captureException(err);
      this.setState({ error: true });
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <Popup
        position="right center"
        className="user-popup"
        on="click"
        onClose={() => {
          this.setState({ users: [], lastVisible: null });
        }}
        onOpen={() => {
          this.loadUsers();
        }}
        trigger={this.props.display}
        wide
      >
        {this.state.users.map((user) => (
          <div key={user.id}>
            <Link to={"/user/" + user.id}>
              <Image src={user.signedImage} avatar />
              <Icon className="no-margin" name="at" />
              {user.username}
            </Link>
          </div>
        ))}
        {this.state.hasMore && (
          <Button onClick={this.loadUsers}>More...</Button>
        )}
      </Popup>
    );
  }
}

RelatedUserSearch.contextType = UserContext;

export default RelatedUserSearch;
