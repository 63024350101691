import React from "react";
import { Search } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import { withRouter } from "react-router";
import { searchUsername } from "../util/DbHelper.js";

class GlobalSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getResults = this.getResults.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.state = { users: [], value: "", loading: false };
  }

  async getResults(e, data) {
    this.setState({ loading: true, value: data.value });
    const results = await searchUsername(data.value);
    let users = [];
    for (const user of results.users) {
      users.push({ title: `@${user.username}`, id: user.id });
    }
    this.setState({ loading: false, users: users });
  }

  onSelect(e, data) {
    this.props.history.push(`/user/${data.result.id}`);
  }

  render() {
    return (
      <Search
        className={this.props.className}
        size={this.props.size}
        loading={this.state.loading}
        onSearchChange={this.getResults}
        onResultSelect={this.onSelect}
        results={this.state.users}
        value={this.state.value}
      />
    );
  }
}

GlobalSearch.contextType = UserContext;

export default withRouter(GlobalSearch);
