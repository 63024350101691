import React from "react";
import { UserContext } from "../contexts/UserContext";
import { Form, Button, Divider, Message } from "semantic-ui-react";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  getAuth,
  updatePassword,
} from "firebase/auth";

class UpdatePassword extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.submitPasswordChange = this.submitPasswordChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.state = {
      oldPassword: "",
      password: "",
      confirmPassword: "",
      error: false,
      success: false,
      errors: [],
    };
  }

  async updatePassword() {
    if (this.context && this.context.uid) {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(
          user.email,
          this.state.oldPassword
        );
        await reauthenticateWithCredential(auth.currentUser, credential);
        await updatePassword(user, this.state.password);
        this.setState({
          error: false,
          success: true,
          oldPassword: "",
          password: "",
          confirmPassword: "",
        });
      } catch (e) {
        this.setState({
          error: true,
          success: false,
          errors: ["An error occured"],
        });
      }
    }
  }

  async submitPasswordChange() {
    this.setState({ loading: true, error: false, success: false });

    if (!this.validatePassword())
      this.setState({ error: true, success: false });
    else await this.updatePassword();

    this.setState({ loading: false });
  }

  validatePassword() {
    let errors = [];
    let formIsValid = true;

    if (!this.state.oldPassword) {
      formIsValid = false;
      errors.push("Please enter current password");
    }
    if (
      this.state.password &&
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/.test(
        this.state.password
      )
    ) {
      formIsValid = false;
      errors.push("Password requires number.");
      errors.push("Password requires special character");
      errors.push("Password require uppercase letter");
      errors.push("Password require lowercase letter");
      errors.push("Minimum password length 8");
    }
    if (!this.state.password) {
      formIsValid = false;
      errors.push("Please provde a password");
    }
    if (
      (this.state.password && !this.state.confirmPassword) ||
      (!this.state.password && this.state.confirmPassword)
    ) {
      formIsValid = false;
      errors.push("Please confirm password");
    }
    if (
      this.state.password &&
      this.state.confirmPassword &&
      this.state.password !== this.state.confirmPassword
    ) {
      formIsValid = false;
      errors.push("Passwords do not match");
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <>
        <Divider horizontal>Password</Divider>
        <Form error={this.state.error} success={this.state.success}>
          <Message success header="Success" content="Password Updated" />
          <Message error>
            <Message.Header>Error</Message.Header>
            <Message.List>
              {this.state.errors.map((rule, index) => (
                <Message.Item key={index}>{rule}</Message.Item>
              ))}
            </Message.List>
          </Message>
          <Form.Field>
            <label>Old Password</label>
            <input
              type="password"
              onChange={this.handleInputChange}
              value={this.state.oldPassword}
              placeholder="Old Password"
              name="oldPassword"
            />
          </Form.Field>
          <Form.Field>
            <label>New Password</label>
            <input
              type="password"
              onChange={this.handleInputChange}
              value={this.state.password}
              placeholder="New Password"
              name="password"
            />
          </Form.Field>
          <Form.Field>
            <label>Confirm New Password</label>
            <input
              type="password"
              onChange={this.handleInputChange}
              value={this.state.confirmPassword}
              placeholder="Confirm New Password"
              name="confirmPassword"
            />
          </Form.Field>
          <Button
            loading={this.state.loading}
            type="submit"
            onClick={this.submitPasswordChange}
            onKeyPress={this.submitPasswordChange}
          >
            Save
          </Button>
        </Form>
      </>
    );
  }
}

UpdatePassword.contextType = UserContext;

export default UpdatePassword;
