import React from "react";
import blankPicture from "../res/blank-profile-picture.png";
import { Image, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getUser } from "../util/DbHelper.js";

class Username extends React.Component {
  constructor(props) {
    super(props);
    this.loadUser = this.loadUser.bind(this);
    this.state = { username: "", image: "" };
  }

  componentDidMount() {
    this.loadUser();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.loadUser();
    }
  }

  async loadUser() {
    if (this.props.id) {
      const user = await getUser(this.props.id);
      this.setState({
        username: user.username,
        image: user.signedImage ? user.signedImage : blankPicture,
      });
    }
  }

  render() {
    return (
      <>
        <Image src={this.state.image} avatar />
        <Icon className="no-margin" name="at" />
        <Link className="user-link" to={"/user/" + this.props.id}>
          {this.state.username}
        </Link>
      </>
    );
  }
}

export default Username;
