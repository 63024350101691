import React from "react";
import Cropper from "react-easy-crop";
import FilepondUploader from "../util/FilepondUploader.js";
import getCroppedImg from "./CropImage";
import { Button, Message } from "semantic-ui-react";
import { UserContext } from "../contexts/UserContext";
import { updateProfilePicture } from "../util/DbHelper.js";
import * as Sentry from "@sentry/react";

class AvatarEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: false,
      preCropImage: "",
      zoom: 1,
      crop: { x: 0, y: 0 },
      croppedAreaPixels: {},
    };
    this.onImageUpload = this.onImageUpload.bind(this);
    this.setZoom = this.setZoom.bind(this);
    this.setCrop = this.setCrop.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.readFile = this.readFile.bind(this);
    this.sliderChange = this.sliderChange.bind(this);
    this.saveCroppedImage = this.saveCroppedImage.bind(this);
  }

  readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  onCropComplete(croppedArea, croppedAreaPixels) {
    this.setState({ croppedAreaPixels });
  }

  async onImageUpload(files) {
    if (files.length > 0) {
      this.onImageUpload = this.onImageUpload.bind(this);
      const file = files[0];
      let imageDataUrl = await this.readFile(file);
      this.setState({ preCropImage: imageDataUrl });
    }
  }

  setZoom(zoom) {
    this.setState({ zoom });
  }

  setCrop(crop) {
    this.setState({ crop });
  }

  sliderChange(e) {
    this.setState({ zoom: e.target.value });
  }

  async saveCroppedImage() {
    if (!this.state.preCropImage) return;

    this.setState({ loading: true });

    try {
      const croppedImage = await getCroppedImg(
        this.state.preCropImage,
        this.state.croppedAreaPixels
      );
      const image = new File([croppedImage], "profile_picture");

      if (this.context && this.context.uid) {
        await updateProfilePicture(this.context.uid, image);
        this.props.updateImage(URL.createObjectURL(croppedImage));
      }
    } catch (err) {
      Sentry.captureException(err);
      this.setState({ error: true, loading: false });
    }

    this.setState({ loading: false });
  }

  render() {
    return (
      <>
        {!this.state.preCropImage && (
          <FilepondUploader maxFiles={1} onImageUpload={this.onImageUpload} />
        )}
        {this.state.preCropImage && (
          <>
            {this.state.error && (
              <Message error>
                <Message.Header>Error</Message.Header>
                <p>Could not update profile picture.</p>
              </Message>
            )}
            <div className="crop-container">
              <Cropper
                cropShape="round"
                image={this.state.preCropImage}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={1}
                onCropChange={this.setCrop}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.setZoom}
              />
            </div>
            <input
              type="range"
              min={1}
              max={3}
              step={0.01}
              value={this.state.zoom}
              onChange={this.sliderChange}
            />
          </>
        )}
        <>
          <Button
            secondary
            loading={this.state.loading}
            onClick={this.saveCroppedImage}
          >
            Save
          </Button>
          <Button
            secondary
            floated="right"
            onClick={this.props.cancelEditImage}
          >
            Cancel
          </Button>
        </>
      </>
    );
  }
}

AvatarEditor.contextType = UserContext;

export default AvatarEditor;
