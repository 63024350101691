import React, { Component } from "react";
import { Button, Modal, Header } from "semantic-ui-react";
import CookiePolicy from "./CookiePolicy";
import PrivacyPolicy from "./PrivacyPolicy";

class CookieConsentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accepted: false,
      open: true,
      openCookiePolicy: false,
      openPrivacyPolicy: false,
    };
  }

  componentDidMount() {
    // Check if the user has previously accepted the cookie and privacy policies
    const consent = document.cookie.match(/consentAccepted=1/);
    if (consent) {
      this.setState({ accepted: true });
    }
  }

  handleAccept = () => {
    // Save the user's acceptance status in a cookie
    document.cookie = "consentAccepted=1; max-age=31536000; path=/";
    this.setState({ accepted: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpenCookiePolicy = () => {
    this.setState({ openCookiePolicy: true });
  };

  handleCloseCookiePolicy = () => {
    this.setState({ openCookiePolicy: false });
  };

  handleOpenPrivacyPolicy = () => {
    this.setState({ openPrivacyPolicy: true });
  };

  handleClosePrivacyPolicy = () => {
    this.setState({ openPrivacyPolicy: false });
  };

  render() {
    const { accepted, open, openCookiePolicy, openPrivacyPolicy } = this.state;

    return (
      <>
        <Modal open={!accepted} size="small">
          <Header icon="privacy" content="Cookie and Privacy Policy" />
          <Modal.Content>
            <p>
              We use cookies to improve your experience on our website. By
              continuing to browse, you accept our use of cookies.
            </p>
            <p>
              Please review our{" "}
              <a onClick={this.handleOpenCookiePolicy}>Cookie Policy</a> and{" "}
              <a onClick={this.handleOpenPrivacyPolicy}>Privacy Policy</a> for
              more information.
            </p>
          </Modal.Content>
          <Modal.Actions>
            {!accepted && (
              <Button positive onClick={this.handleAccept}>
                Accept
              </Button>
            )}
          </Modal.Actions>
        </Modal>

        <Modal
          open={openCookiePolicy}
          onClose={this.handleCloseCookiePolicy}
          size="large"
        >
          <Header>Cookie Policy</Header>
          <Modal.Content>
            <CookiePolicy />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCloseCookiePolicy}>Close</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={openPrivacyPolicy}
          onClose={this.handleClosePrivacyPolicy}
          size="large"
        >
          <Header>Privacy Policy</Header>
          <Modal.Content>
            <PrivacyPolicy />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleClosePrivacyPolicy}>Close</Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default CookieConsentModal;
