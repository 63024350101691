import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { withRouter } from "react-router";

class ImageCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.redirect = this.redirect.bind(this);
  }

  redirect() {
    if (this.props.allowRedirect)
      this.props.history.push("/look/" + this.props.lookId);
  }

  render() {
    let images = [];
    if (this.props.images) {
      images = this.props.images.map((image, index) => (
        <div key={index}>
          <img alt="" src={image} />
        </div>
      ));
    }

    return (
      <Carousel
        onClickItem={this.redirect}
        dynamicHeight={true}
        showThumbs={this.props.thumb}
        autoPlay={false}
      >
        {images}
      </Carousel>
    );
  }
}

export default withRouter(ImageCarousel);
