export let categories = {
  SMART: { categoryColour: "red", display: "Smart" },
  CASUAL: { categoryColour: "blue", display: "Casual" },
  GOING_OUT: { categoryColour: "orange", display: "Going Out" },
  GYM: { categoryColour: "olive", display: "Gym" },
  OFFICE: { categoryColour: "green", display: "Office" },
  VINTAGE: { categoryColour: "yellow", display: "Vintage" },
  CHIC: { categoryColour: "teal", display: "Chic" },
  STREET: { categoryColour: "violet", display: "Street" },
  ALT: { categoryColour: "grey", display: "Alt" },
  HOLIDAY: { categoryColour: "pink", display: "Holiday" },
};

export let height = {
  SMALL: { categoryColour: "black" },
  MEDIUM: { categoryColour: "black" },
  TALL: { categoryColour: "black" },
};

export let bodyType = {
  SLIM: { categoryColour: "black" },
  MEDIUM: { categoryColour: "black" },
  LARGE: { categoryColour: "black" },
};

export const genderOptions = [
  {
    key: "MALE",
    text: "Male",
    value: "MALE",
  },
  {
    key: "FEMALE",
    text: "Female",
    value: "FEMALE",
  },
  {
    key: "OTHER",
    text: "Other",
    value: "OTHER",
  },
  {
    key: "ALL",
    text: "All",
    value: "ALL",
  },
];

export const sortOptions = [
  {
    key: "RECENT",
    text: "Most recent",
    value: "RECENT",
  },
  {
    key: "LIKES",
    text: "No. likes high to low",
    value: "LIKES",
  },
];

export const currencyOptions = [
  {
    key: "GBP",
    text: "£",
    value: "GBP",
  },
  {
    key: "USD",
    text: "$",
    value: "USD",
  },
  {
    key: "EUR",
    text: "€",
    value: "EUR",
  },
];

export const currencyMapping = {
  GBP: "£",
  USD: "$",
  EUR: "€",
};
