import React from "react";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

class FilepondUploader extends React.Component {
  handleInit() {
    //console.log("FilePond instance has initialised", this.pond);
  }

  render() {
    return (
      <FilePond
        allowFileTypeValidation={true}
        acceptedFileTypes={["image/*"]}
        ref={(ref) => (this.pond = ref)}
        allowMultiple={true}
        allowReorder={true}
        maxFiles={this.props.maxFiles}
        oninit={() => this.handleInit()}
        onreorderfiles={(fileItems) => {
          let files = fileItems.map((fileItem) => fileItem.file);
          this.props.onImageUpload(files);
        }}
        onupdatefiles={(fileItems) => {
          let files = fileItems.map((fileItem) => fileItem.file);
          this.props.onImageUpload(files);
        }}
      />
    );
  }
}

export default FilepondUploader;
