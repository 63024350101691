import React from "react";
import { UserContext } from "../contexts/UserContext";
import {
  Form,
  Button,
  Divider,
  Message,
  Dropdown,
  Loader,
} from "semantic-ui-react";
import { genderOptions } from "../res/options.js";
import { getUser, updateUserPreferences } from "../util/DbHelper.js";

class UpdatePreferences extends React.Component {
  constructor(props, context) {
    super(props);
    this.submitPreferenceChange = this.submitPreferenceChange.bind(this);
    this.updatePreferences = this.updatePreferences.bind(this);
    this.genderChange = this.genderChange.bind(this);
    this.getGender = this.getGender.bind(this);
    this.state = {
      gender: "",
      error: false,
      success: false,
      errors: [],
    };
  }

  componentDidMount() {
    this.getGender();
  }

  async getGender() {
    if (this.context && this.context.uid) {
      this.setState({ loading: true });
      const user = await getUser(this.context.uid);
      this.setState({
        gender: user.gender,
        loading: false,
      });
    }
  }

  genderChange(event, { value }) {
    this.setState({ gender: value });
  }

  async updatePreferences() {
    this.setState({ loading: true, error: false, sucess: false });
    if (!this.state.gender)
      this.setState({
        error: true,
        success: false,
        errors: ["Please choose a gender"],
      });
    else await this.submitPreferenceChange();
    this.setState({ loading: false });
  }

  async submitPreferenceChange() {
    if (this.context && this.context.uid) {
      try {
        await updateUserPreferences(this.context.uid, this.state.gender);
        this.setState({
          error: false,
          success: true,
        });
      } catch (e) {
        this.setState({
          error: true,
          success: false,
        });
      }
    }
  }

  render() {
    return (
      <>
        <Divider horizontal>Preferences</Divider>
        {this.state.loading && (
          <Loader active inline="centered" size="massive" />
        )}
        {!this.state.loading && (
          <Form error={this.state.error} success={this.state.success}>
            <Message
              success
              header="Success"
              content="Account details updated"
            />
            <Message error>
              <Message.Header>Error</Message.Header>
              <Message.List>
                {this.state.errors.map((rule, index) => (
                  <Message.Item key={index}>{rule}</Message.Item>
                ))}
              </Message.List>
            </Message>
            <Form.Field>
              <label>What looks would you like to see?</label>
              <Dropdown
                fluid
                selection
                value={this.state.gender}
                onChange={this.genderChange}
                options={genderOptions}
              />
            </Form.Field>
            <Button
              loading={this.state.loading}
              type="submit"
              onClick={this.updatePreferences}
            >
              Save
            </Button>
          </Form>
        )}
      </>
    );
  }
}

UpdatePreferences.contextType = UserContext;

export default UpdatePreferences;
