import React from "react";
import { Icon, Confirm } from "semantic-ui-react";
import { withRouter } from "react-router";
import { deleteLook } from "../util/DbHelper.js";
import { UserContext } from "../contexts/UserContext";
import * as Sentry from "@sentry/react";

class DeleteLookButton extends React.Component {
  constructor(props) {
    super(props);
    this.openConirm = this.openConirm.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.delete = this.delete.bind(this);

    this.state = { confirmOpen: false };
  }

  openConirm() {
    this.setState({ confirmOpen: true });
  }

  closeConfirm() {
    this.setState({ confirmOpen: false });
  }

  async delete() {
    if (this.context && this.context.uid) {
      this.setState({ confirmOpen: false });
      try {
        deleteLook(
          this.props.look.id,
          this.props.look.images,
          this.context.uid
        );
        this.props.history.push("/user/" + this.props.userId);
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  }

  render() {
    return (
      <>
        <Icon link name="trash" onClick={this.openConirm} />
        <Confirm
          open={this.state.confirmOpen}
          header="Permanently delete look?"
          onCancel={this.closeConfirm}
          onConfirm={this.delete}
        />
      </>
    );
  }
}

DeleteLookButton.contextType = UserContext;

export default withRouter(DeleteLookButton);
