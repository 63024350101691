import React from "react";
import CardGroup from "./CardGroup.js";
import { Segment } from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";

class InfiniteFeed extends React.Component {
  render() {
    return (
      <>
        <InfiniteScroll
          dataLength={this.props.looks.length}
          next={this.props.loadLooks}
          hasMore={this.props.hasMore}
          scrollableTarget="pinzone"
          loader={
            <div className="lds-ellipsis center">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          }
        >
          <Segment>
            <CardGroup
              noColumns={this.props.noColumns}
              looks={this.props.looks}
            />
            {this.props.error && <p>Error loading feed</p>}
          </Segment>
        </InfiniteScroll>
      </>
    );
  }
}

export default InfiniteFeed;
