import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import { DeviceContext } from "../contexts/DeviceContext";
import UpdatePassword from "./UpdatePassword.js";
import UpdateAccount from "./UpdateAccount.js";
import UpdatePreferences from "./UpdatePreferences.js";

class Account extends React.Component {
  render() {
    return (
      <DeviceContext.Consumer>
        {(device) => (
          <Grid centered columns={device.type === "mobile" ? 1 : 2}>
            <Grid.Column>
              <Segment>
                <UpdatePassword />
                <UpdateAccount />
                <UpdatePreferences />
              </Segment>
            </Grid.Column>
          </Grid>
        )}
      </DeviceContext.Consumer>
    );
  }
}

export default Account;
