import React from "react";
import CategorySelector from "../util/CategorySelector.js";
import { Dropdown, List, Segment, Form } from "semantic-ui-react";
import { genderOptions, sortOptions } from "../res/options.js";

class FilterMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { search: "" };
  }

  render() {
    return (
      <>
        <Segment>
          <Form>
            <List>
              <List.Item>
                <p>Gender</p>
                <Dropdown
                  fluid
                  selection
                  value={this.props.gender}
                  onChange={this.props.genderChange}
                  options={genderOptions}
                />
              </List.Item>
              <List.Item>
                <p>Category</p>
                <CategorySelector
                  categoryChange={this.props.categoryChange}
                  categories={this.props.categories}
                />
              </List.Item>
              <List.Item>
                <p>Sort By</p>
                <Dropdown
                  fluid
                  selection
                  options={sortOptions}
                  onChange={this.props.sortChange}
                  value={this.props.sort}
                  defaultValue="RECENT"
                />
              </List.Item>
            </List>
          </Form>
        </Segment>
      </>
    );
  }
}

export default FilterMenu;
